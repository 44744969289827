<a
  bossSetActiveState
  elementUrl="cart"
  class="mini-cart"
  [attr.aria-label]="'miniCart.item' | cxTranslate: { count: quantity$ | async }"
  [routerLink]="{ cxRoute: 'cart' } | cxUrl"
>
  <i bossIcon class="cart-icon" [type]="bossIconConfig.CART" aria-hidden="true">
    <ng-container *ngIf="quantity$ | async as quantity">
      <span class="count" *ngIf="quantity > 0">
        {{ quantity }}
      </span>
    </ng-container>
  </i>
  <span class="title">{{ 'bossMiniCart.title' | cxTranslate }}</span>
</a>
