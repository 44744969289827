import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BossWindowObject, UCEvent } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserCentricsService {
  private consentSubject = new Map<string, BehaviorSubject<boolean>>();
  private usercentricsMaps: Map<string, string> = new Map<string, string>();

  constructor(private winRef: WindowRef) {
    this.initWindow();
  }

  private initWindow(): void {
    if (this.winRef.isBrowser()) {
      window.addEventListener('ucEvent', (e: UCEvent) => {
        if (e.detail?.event == 'consent_status') {
          const unallowedKeys = ['action', 'event', 'type'];
          const consentStatuses = Object.entries(e.detail).filter(([key]) => !unallowedKeys.includes(key));
          consentStatuses.forEach(([serviceName, status]) => {
            this.setSpecificConsentState(serviceName, status as boolean);
          });
        }
      });
    }
  }

  getConsent(consentName: string): Observable<boolean> {
    this.setSpecificConsent(consentName);

    if (!this.consentSubject.has(consentName)) {
      this.consentSubject.set(consentName, new BehaviorSubject<boolean>(false));
      this.consentSubject.get(consentName).next(false);
    }

    return this.consentSubject.get(consentName);
  }

  setSpecificConsent(consentName: string): void {
    if (this.winRef.isBrowser()) {
      const ucWindow: BossWindowObject = window as unknown as BossWindowObject;

      if (!ucWindow || !ucWindow.UC_UI || !ucWindow.UC_UI.isInitialized()) {
        return;
      }

      if (!this.usercentricsMaps.has(consentName)) {
        const found = ucWindow.UC_UI.getServicesBaseInfo().find((element) => element.name == consentName);

        if (found) {
          this.setSpecificConsentState(consentName, found.consent.status);
          this.usercentricsMaps.set(found.name, found.id);
        }
      }
    }
  }

  setConsent(consentName: string, consetStatus: boolean): void {
    if (this.winRef.isBrowser()) {
      const ucWindow: BossWindowObject = window as unknown as BossWindowObject;

      if (!ucWindow || !ucWindow.UC_UI || !ucWindow.UC_UI.isInitialized()) {
        return;
      }

      // Check if the ID is static and implement it directly.
      // setup the name/id map if it does not exist
      if (!this.usercentricsMaps.has(consentName)) {
        ucWindow.UC_UI.getServicesBaseInfo().forEach((element) => {
          this.usercentricsMaps.set(element.name, element.id);
        });
      }

      if (!this.usercentricsMaps.has(consentName)) {
        return;
      }

      // use consentID instead of Name.
      const currentConsentID = this.usercentricsMaps.get(consentName);

      if (consetStatus === true) {
        ucWindow.UC_UI.acceptService(currentConsentID);

        return;
      } else if (consetStatus === false) {
        ucWindow.UC_UI.rejectService(currentConsentID);

        return;
      }
    }
  }

  openCookieMenu(): void {
    if (this.winRef.isBrowser()) {
      const ucWindow: BossWindowObject = window as unknown as BossWindowObject;

      if (!ucWindow || !ucWindow.UC_UI || !ucWindow.UC_UI.isInitialized()) {
        return;
      }

      ucWindow.UC_UI.showSecondLayer();
    }
  }

  private setSpecificConsentState(name: string, status: boolean): void {
    if (!this.consentSubject.has(name)) {
      this.consentSubject.set(name, new BehaviorSubject<boolean>(false));
    }

    this.consentSubject.get(name).next(status);
  }
}
