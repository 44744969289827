<div [formGroup]="formGroup">
  <div [class.range-slider-disabled]="isDisabled" class="range-slider">
    <div [style.left]="left" [style.right]="right" class="progress"></div>

    <div class="range-min-wrapper">
      <input
        tabindex="-1"
        formControlName="min"
        class="range-min"
        type="range"
        min="{{ range.min }}"
        max="{{ range.max }}"
      />
    </div>

    <div class="range-max-wrapper">
      <input
        tabindex="-1"
        formControlName="max"
        class="range-max"
        type="range"
        min="{{ range.min }}"
        max="{{ range.max }}"
      />
    </div>
  </div>
</div>
