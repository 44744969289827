<button
  type="button"
  (click)="openModal()"
  class="boss-filters-btn boss-btn boss-btn-primary boss-btn-full dialog-trigger"
  >{{ 'productList.filterBy.label' | cxTranslate }}
  <i bossIcon [type]="bossIconConfig.FILTER" aria-hidden="true"></i>
</button>

<div
  class="boss-facet-list"
  *ngIf="showFacets$ | async"
  [class.active]="isModalOpen$ | async"
  [class.dialog]="isDialog"
>
  <div *ngIf="facetList?.facets as facets" class="inner">
    <div class="facet-filter-wrapper">
      <span class="boss-h5 text-transform-none">
        {{ 'productList.filterBy.label' | cxTranslate }}
        <button
          (click)="closeModal()"
          [attr.aria-label]="'productList.filterBy.close' | cxTranslate"
          class="close"
          type="button"
        >
          <i bossIcon [type]="bossIconConfig.CLOSE" aria-hidden="true"></i>
        </button>
      </span>

      <ng-container *ngFor="let facet of facets">
        <ng-container [ngSwitch]="facet?.filterType">
          <boss-collapse
            *ngIf="facet.filterType !== BossFilterType.SWITCH; else filterSwitch"
            [headline]="facet.name"
            [isExpanded]="isCollapsed(facet) | async"
            (shouldBeExpanded)="updateFacetExtensibility(facet, $event)"
          >
            <boss-active-facets
              *ngIf="isDialog"
              [activeFacets]="facetList.activeFacets"
              [facetName]="facet.name"
              [isDialog]="isDialog"
              ngProjectAs="sub-headline"
            >
            </boss-active-facets>

            <boss-filter-range
              *ngSwitchCase="BossFilterType.RANGE"
              [facet]="facet"
              [activeFacets]="facetList.activeFacets"
            ></boss-filter-range>

            <boss-facet-checkbox *ngSwitchDefault [facet]="facet"></boss-facet-checkbox>
          </boss-collapse>

          <ng-template #filterSwitch>
            <boss-facet-switch [facet]="facet"></boss-facet-switch>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>

    <boss-facets-dialog-buttons
      *ngIf="isDialog"
      [facetList]="facetList"
      (closeDialog)="closeModal()"
      (removeFilters)="removeAllFilters()"
    >
    </boss-facets-dialog-buttons>
  </div>
</div>
