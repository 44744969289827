import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { isPlatformServer } from '@angular/common';
import { carouselConfigBrowser, carouselConfigServer } from './boss-carousel-base.config';

@Component({
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export abstract class BossCarouselBaseComponent implements OnInit {
  swiperConfig: SwiperOptions;

  items: any;

  protected swiperConfigServer: SwiperOptions = carouselConfigServer;

  protected swiperConfigBrowser: SwiperOptions = carouselConfigBrowser;

  protected isSsr = false;

  protected constructor(@Inject(PLATFORM_ID) platformId: any) {
    this.isSsr = isPlatformServer(platformId);
  }

  ngOnInit(): void {
    this.loadSwiperConfig();
    this.loadItems();
  }

  trackById(item: any): string {
    return item.uid;
  }

  protected loadSwiperConfig(): void {
    this.swiperConfig = this.isSsr ? this.swiperConfigServer : this.swiperConfigBrowser;
  }

  protected abstract loadItems(): void;
}
