<div class="navigation-wrapper">
  <div class="navigation-container">
    <ng-container *ngIf="navEntries$ | async as navEntries">
      <ng-container *ngIf="isMobile$ | async; else desktop">
        <ng-container *ngIf="mobileNavEntries$ | async as mobileNavEntriess">
          <div class="boss-mobile-navigation-sublevel-wrapper">
            <div class="boss-mobile-navigation-sublevel">
              <!-- Backbutton header to stick on top -->
              <div class="boss-navigation-backmenu" *ngIf="mobileSelectedCategory?.length > 1">
                <a class="boss-navigation-back copy" (click)="mobileGoBack()">
                  <i bossIcon [type]="bossIconConfig.CARET_LEFT" aria-hidden="true"></i>
                  <span>
                    Zurück zu
                    <b class="copy">{{ mobileSelectedCategory[mobileSelectedCategory.length - 2] }}</b>
                  </span>
                </a>
              </div>
              <!-- All links with overflow scroll -->
              <ul class="boss-navigation">
                <!-- Parent Link -->
                <li class="boss-navigation-item" *ngIf="mobileSelectedCategory?.length > 1">
                  <a
                    class="boss-navigation-link copy-bold"
                    [routerLink]="mobileSelectedCategoryUrl[mobileSelectedCategoryUrl.length - 1]"
                  >
                    {{ mobileSelectedCategory[mobileSelectedCategory.length - 1] }}
                  </a>
                </li>
                <!-- All other links -->
                <li
                  class="boss-navigation-item"
                  *ngFor="let navEntry of mobileNavEntriess[mobileNavEntriess.length - 1]"
                  [ngClass]="navEntry.styleClass ? navEntry.styleClass : ''"
                >
                  <ng-container *ngIf="navEntry.linkName; else subLevel">
                    <a class="boss-navigation-link copy" [routerLink]="navEntry.url">
                      {{ navEntry.linkName }}
                    </a>
                  </ng-container>
                  <ng-template #subLevel>
                    <ng-container *ngIf="navEntry.children; else withoutChildren">
                      <a class="boss-navigation-link copy boss-navigation-arrow" (click)="setMobileContent(navEntry)">
                        {{ navEntry.title }}
                        <i bossIcon [type]="bossIconConfig.CARET_RIGHT" aria-hidden="true"></i>
                      </a>
                    </ng-container>
                    <ng-template #withoutChildren>
                      <ng-container *ngIf="navEntry.links; else withoutLinks">
                        <a class="boss-navigation-link copy boss-navigation-arrow" (click)="setMobileContent(navEntry)">
                          {{ navEntry.title }}
                          <i bossIcon [type]="bossIconConfig.CARET_RIGHT" aria-hidden="true"></i>
                        </a>
                      </ng-container>
                      <ng-template #withoutLinks>
                        <a *ngIf="navEntry.title" class="boss-navigation-link copy" [routerLink]="navEntry.url">
                          {{ navEntry.title }}
                        </a>
                      </ng-template>
                    </ng-template>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #desktop>
        <div (mouseleave)="navPointerLeft()">
          <ul class="boss-navigation">
            <li
              class="boss-navigation-item"
              *ngFor="let navEntry of navEntries"
              [ngClass]="[
                navEntry.styleClass ? navEntry.styleClass : '',
                activeNav === navEntry.url ? 'boss-is-active' : ''
              ]"
              (mouseenter)="navItemHovered(navEntry)"
              (click)="onSelectNav(navEntry)"
            >
              <a class="boss-navigation-link copy" [routerLink]="navEntry.url"
                ><span>{{ navEntry.title }}</span></a
              >
            </li>
          </ul>

          <div [classList]="'boss-navigation-background'" *ngIf="hasSubNavContent$ && flyOutContent$ | async">
            <!-- Decide between checkbanner or full width nav -->
            <div [classList]="'container boss-navigation-sublevel'" [ngClass]="{ checkbanner: checkBanner }">
              <div class="boss-navigation-list-grid">
                <ul class="link-list" *ngFor="let nav of flyOutContent$ | async">
                  <li class="main-category">
                    <a
                      class="copy"
                      [routerLink]="nav.url"
                      (click)="flyOutContent.next(undefined); activeNav = currentNav"
                    >
                      {{ nav.title }}
                    </a>
                  </li>
                  <!-- ToDo: add sub categories-->
                  <ul *ngIf="!checkBanner">
                    <!-- Only show the first 5 links then cut -->
                    <ng-container *ngFor="let link of nav.links; let i = index">
                      <li *ngIf="i < 5" class="sub-category">
                        <a
                          class="copy"
                          [routerLink]="link.url"
                          (click)="flyOutContent.next(undefined); activeNav = currentNav"
                          >{{ link.linkName }}
                        </a>
                      </li>
                    </ng-container>
                    <!-- Only show if sub links length is greater then 5-->
                    <li *ngIf="nav.links?.length > 5" class="sub-category show-all">
                      <a
                        class="copy"
                        [routerLink]="nav.url"
                        (click)="flyOutContent.next(undefined); activeNav = currentNav"
                      >
                        Alle anzeigen
                      </a>
                    </li>
                  </ul>
                </ul>
              </div>
              <ng-container *ngIf="secondLevelNavi.banner as banner">
                <div class="boss-navigation-visual">
                  <a [routerLink]="secondLevelNavi.url">
                    <img
                      [src]="getUrl(banner.media)"
                      [alt]="banner.media?.altText || secondLevelNavi.title"
                      loading="lazy"
                    />
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
