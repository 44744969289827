import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserCentricsService } from './usercentrics.service';
import { WindowRef } from '@spartacus/core';

export enum AWIN {
  AWIN_TOKEN_KEY = 'awinToken',
  AWIN_TOKEN_QUERY = 'awc',
  AWIN_CONCENT_KEY = 'AWIN',
}

@Injectable({
  providedIn: 'root',
})
export class AwinService implements OnDestroy {
  private awinToken = '';
  private initialized = false;
  private userCentricsSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(private userCentrics: UserCentricsService, private router: Router, private winRef: WindowRef) {
    this.routerSubscription = this.router.events.subscribe((ev) => {
      if (!(ev instanceof NavigationEnd)) return;
      const url = (ev as any).url || null;
      let urlSearchParams: URLSearchParams = new URLSearchParams();
      if (url) {
        try {
          urlSearchParams = new URLSearchParams(url.split('?').pop());
        } catch (e) {
          console.log(e);
        }
        this.initialize(urlSearchParams);
      }
      if (this.initialized) this.routerSubscription.unsubscribe();
    });
  }

  token(): string {
    return this.awinToken || this.getTokenFromCookie();
  }

  private initialize(url: URLSearchParams): void {
    if (this.winRef.isBrowser()) {
      if (this.userCentricsSubscription) this.userCentricsSubscription.unsubscribe();
      this.userCentricsSubscription = this.userCentrics
        .getConsent(AWIN.AWIN_CONCENT_KEY)
        .subscribe(async (hasConsent) => {
          if (hasConsent) {
            this.initialized = true;
            try {
              const token = await this.setToken(url.get(AWIN.AWIN_TOKEN_QUERY));
              this.saveToken(token);
            } catch (e) {
              console.log(e);
            }
            this.userCentricsSubscription.unsubscribe();
          }
        });
    }
  }

  private setToken(queryToken: string): Promise<string> {
    return new Promise((resolve) => {
      const token: string = queryToken || '';
      this.awinToken = token || this.getTokenFromCookie();
      resolve(token);
    });
  }

  private expiry(): string {
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + 1);
    return expiry.toUTCString();
  }

  private getCookie(cookie: string): string {
    const parsedCookieData: string = decodeURIComponent(cookie)
      .split(';')
      .filter((v) => v.trim().split('=')[0] === AWIN.AWIN_TOKEN_KEY)
      .pop();
    if (!parsedCookieData) return '';
    return parsedCookieData.trim().replace(`${AWIN.AWIN_TOKEN_KEY}=`, '');
  }

  getTokenFromCookie(): string {
    if (this.winRef.isBrowser()) {
      return this.getCookie(window.document.cookie);
    }
    return '';
  }

  deleteToken(): void {
    const cookie = `${AWIN.AWIN_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path = /;`;

    if (this.winRef.isBrowser()) {
      window.document.cookie = cookie;
    }
  }

  saveToken(token: string): string {
    if (!token) return token;
    this.deleteToken();
    const cookie = `${AWIN.AWIN_TOKEN_KEY}=${token}; expires=${this.expiry()}; path = /;`;
    if (this.winRef.isBrowser()) {
      window.document.cookie = cookie;
    }
    return token;
  }

  ngOnDestroy(): void {
    if (this.userCentricsSubscription) this.userCentricsSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}
