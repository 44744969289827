<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
  <button
    [attr.aria-label]="'bossBackToTop.label' | cxTranslate"
    type="button"
    class="boss-btn boss-btn-icon focus-visible"
    data-toggle="button"
    (click)="scrollToTop()"
    [attr.aria-label]="'common.scrollTop' | cxTranslate"
  >
    <i bossIcon [type]="bossIconConfig.CARET_UP" aria-hidden="true"></i>
  </button>
</div>
