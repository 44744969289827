import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable()
export class FooterMiddleService {
  constructor(private http: HttpClient, private occEndpoints: OccEndpointsService) {}

  public getByUuid(id: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(`cms/components/${id}`);
    return this.http.get(url);
  }

  public createEndpointArray(stringList: any): Observable<any>[] {
    const observableArr = [];
    stringList.split(' ').forEach((elm: string) => {
      observableArr.push(this.getByUuid(elm));
    });
    return observableArr;
  }
}
