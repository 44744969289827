<form *ngIf="productCode" [formGroup]="addToCartForm" (submit)="addToCart()">
  <div class="quantity" *ngIf="showQuantity">
    <label>{{ 'addToCart.quantity' | cxTranslate }}</label>
    <cx-item-counter *ngIf="hasStock" [max]="maxQuantity" [control]="addToCartForm.get('quantity')"></cx-item-counter>
    <span class="info">{{
      hasStock ? ('addToCart.inStock' | cxTranslate) : ('addToCart.outOfStock' | cxTranslate)
    }}</span>
  </div>

  <button
    class="boss-btn boss-btn-magenta boss-btn-full {{ additionalModifierClasses }}"
    type="submit"
    [disabled]="quantity <= 0 || quantity > maxQuantity || !hasStock || !isPurchasable"
  >
    <ng-content></ng-content>
    <span>{{ 'addToCart.addToCartWishlist' | cxTranslate }}</span>
  </button>
</form>
