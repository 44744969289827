<div class="swatches-wrapper" *ngIf="swatches.length">
  <div class="swatch-label" *ngIf="showSwatchLabel">{{ swatchTypeLabel }}: {{ selectedSwatch.name }}</div>

  <div class="swatches">
    <ng-container *ngIf="anchor; else button">
      <a
        [routerLink]="swatch.productUrl"
        class="swatch"
        *ngFor="let swatch of swatches; index as i"
        [attr.title]="swatch.media.altText"
        [ngClass]="{ selected: swatch.selected, hidden: i >= maxVisibleSwatches && !expanded }"
      >
        <ng-container *ngIf="swatch?.colorHexCodeList?.length === 1; else image">
          <span class="swatch-color" [ngStyle]="{ 'background-color': swatch?.colorHexCode }"></span>
        </ng-container>

        <ng-template #image>
          <img
            bossMedia
            [alt]="swatch.media.altText"
            [isAboveTheFold]="false"
            [container]="$any(swatch.media ? swatch.media : null)"
          />
        </ng-template>
      </a>
    </ng-container>

    <ng-template #button>
      <button
        type="button"
        class="swatch"
        *ngFor="let swatch of swatches; index as i"
        [attr.title]="swatch.media.altText"
        [ngClass]="{ selected: swatch.selected, hidden: i >= maxVisibleSwatches && !expanded }"
        (click)="select(swatch.productCode)"
        [attr.aria-label]="'addToCart.selectStyleAndSize' | cxTranslate"
      >
        <ng-container *ngIf="swatch?.colorHexCodeList?.length === 1; else image">
          <span class="swatch-color" [ngStyle]="{ 'background-color': swatch?.colorHexCode }"></span>
        </ng-container>

        <ng-template #image>
          <img
            bossMedia
            [alt]="swatch.media.altText"
            [isAboveTheFold]="false"
            [container]="$any(swatch.media ? swatch.media : null)"
          />
        </ng-template>
      </button>
    </ng-template>

    <button
      [attr.aria-label]="'bossProductSwatches.labelShowMore' | cxTranslate"
      type="button"
      class="swatch-show-more"
      *ngIf="swatches.length > maxVisibleSwatches && !expanded"
      (click)="showMoreOrRedirect()"
      [attr.aria-label]="'productFacetNavigation.showMore' | cxTranslate"
    >
      <i bossIcon class="icon" [type]="bossIconConfig.ADD" aria-hidden="true"></i>
    </button>
  </div>
</div>
