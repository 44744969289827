<div cxPageTemplateStyle>
  <ng-container *ngIf="isCheckoutPage$ | async; else defaultHeader">
    <cx-page-layout section="CheckoutHeader"></cx-page-layout>
    <boss-global-message class="global-messages-checkout"></boss-global-message>
  </ng-container>

  <ng-template #defaultHeader>
    <header
      bossHideHeader
      class="page-header"
      (keydown.escape)="collapseMenu()"
      (click)="collapseMenuIfClickOutside($event)"
      cxSkipLink="cx-header"
      [cxFocus]="{ disableMouseFocus: true }"
      [class.is-expanded]="(isExpanded$ | async) && (isMobile$ | async)"
    >
      <cx-page-slot position="HeaderSalesBanner"></cx-page-slot>
      <cx-page-layout section="header"></cx-page-layout>
      <cx-page-layout section="navigation"></cx-page-layout>
      <boss-global-message></boss-global-message>
    </header>
  </ng-template>

  <div class="header-before-wrapper">
    <cx-page-layout section="headerBefore"></cx-page-layout>
  </div>

  <cx-page-layout section="headerAfter"></cx-page-layout>

  <main cxSkipLink="cx-main" [cxFocus]="{ disableMouseFocus: true }" class="main">
    <router-outlet></router-outlet>
  </main>

  <cx-page-layout section="footerBefore"></cx-page-layout>

  <ng-template cxOutlet="cx-footer">
    <ng-container *ngIf="isCheckoutPage$ | async; else defaultFooter">
      <cx-page-layout section="CheckoutFooter"></cx-page-layout>
    </ng-container>
    <ng-template #defaultFooter>
      <footer cxSkipLink="cx-footer" [cxFocus]="{ disableMouseFocus: true }">
        <cx-page-layout section="footer">
          <div class="wrapper-top">
            <cx-page-slot class="container" position="FooterTopGrey"></cx-page-slot>
          </div>

          <section class="wrapper-middle">
            <cx-page-slot class="container" position="FooterCenter"> </cx-page-slot>
          </section>

          <section class="wrapper-bottom">
            <div class="container">
              <cx-page-slot position="FooterBottom"></cx-page-slot>
              <p class="small-copy legal">© SB-Möbel Boss Online Shop GmbH & Co. KG</p>
            </div>
          </section>
        </cx-page-layout>
      </footer>
    </ng-template>
  </ng-template>

  <boss-back-to-top></boss-back-to-top>
</div>
