import { Injector } from '@angular/core';

export enum BossDialogType {
  DRAWER = 'drawer',
  MODAL = 'modal',
}

export enum BossDialogSize {
  SM = '300px',
  MD = '480px',
  LG = '800px',
  XL = '1140px',
}

export interface BossDialogOptions {
  type?: BossDialogType;
  size?: BossDialogSize;
  data?: any;
  isCloseButtonVisible?: boolean;
  injector?: Injector;
}
