<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="product?.averageRating && product?.reviews?.length">
    <div class="product-review-rating">
      <div class="product-review-rating-anchor">
        <span id="product-reviews"></span>
      </div>
      <div class="product-review-rating__col">
        <div class="product-review-rating__summary">
          <h2>KUNDENREZENSION</h2>
          <img
            src="/assets/img/ekomi.png"
            alt=""
            class="product-review-rating__logo"
            loading="lazy"
            alt="ekomi-rating-company"
          />
          <div class="row">
            <div class="col-xs-6 d-flex justify-content-center align-items-center">
              <div class="product-review-rating__right d-flex flex-column justify-content-center align-items-center">
                <span class="product-review-rating__average">{{ getAverageRating(product) }}</span>
                <div>
                  <boss-product-star-rating
                    [rating]="product.averageRating"
                    [showCount]="false"
                    [anchorLink]="false"
                  ></boss-product-star-rating>
                </div>
                <p class="icon-before icon-my-account">
                  <span>{{ product.reviews.length }}</span> insgesamt
                </p>
              </div>
            </div>
            <div class="col-xs-6">
              <ul class="product-review-rating__list">
                <li
                  *ngFor="let _ of [1, 2, 3, 4, 5]; let i = index"
                  class="d-flex align-items-center product-review-rating__line"
                >
                  <i bossIcon class="product-review__star" [type]="bossIconConfig.STAR" aria-hidden="true"></i>
                  <span class="mx-1"> {{ 5 - i }} </span>
                  <span class="product-review-rating__bar"
                    ><span [style]="{ width: calculateReviewWidth(product?.reviews, 5 - i) }"></span
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="product-review-rating__col">
        <div id="reviews" class="product-review-rating__reviews">
          <div *ngFor="let review of product?.reviews" class="product-review-rating__review">
            <div class="product-review-rating__author">{{ getAuthor(review?.principal) }}</div>
            <div>
              <boss-product-star-rating
                [rating]="review?.rating"
                [showCount]="false"
                [anchorLink]="false"
              ></boss-product-star-rating>
            </div>
            <div class="product-review-rating__comment">{{ review?.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
