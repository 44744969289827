import { Injectable } from '@angular/core';
import { CxEvent } from '@spartacus/core';
import { WindowObject } from '@spartacus/tracking/tms/core';
import { GtmCollectorService } from '@spartacus/tracking/tms/gtm';
import { BossGtmCollectorConfig } from './boss-gtm-collector-config';

@Injectable({ providedIn: 'root' })
export class BossGtmCollectorService extends GtmCollectorService {
  windowObject: WindowObject;

  dataLayerProperty: string;

  init(config: BossGtmCollectorConfig, windowObject: WindowObject): void {
    this.dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
    this.windowObject = windowObject;
    this.windowObject[this.dataLayerProperty] = windowObject[this.dataLayerProperty] ?? [];

    this.registerConsentModeV2();

    if (config.gtmId) {
      this.initGtm(config, this.windowObject);
    }
  }

  map<T extends CxEvent>(event: CxEvent): object | T {
    const eventType = event.constructor?.['type'];
    return { ...event, event: eventType };
  }

  /**
   * Register an item within data layer for GTM
   *
   * @param {string} [operation]
   * @param {string} [description]
   * @param {*} [value]
   */
  private registerDataLayerItem(operation?: string, description?: string, value?: any): void {
    this.windowObject[this.dataLayerProperty].push(arguments);
  }

  /**
   * Register data layer items according to the new Consent Mode
   */
  private registerConsentModeV2(): void {
    this.registerDataLayerItem('consent', 'default', {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      wait_for_update: 2000,
    });
    this.registerDataLayerItem('set', 'ads_data_redaction', true);
  }

  /**
   * Main function which adds GTM script
   *
   * @param {BossGtmCollectorConfig} config
   * @param {WindowObject} windowObject
   */
  private initGtm(config: BossGtmCollectorConfig, windowObject: WindowObject): void {
    if (this.winRef.isBrowser()) {
      (function (w: WindowObject, d: Document, s: string, l: string, i: string): void {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s) as HTMLScriptElement;
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode?.insertBefore(j, f);
      })(windowObject, this.winRef.document, 'script', this.dataLayerProperty, config.gtmId);
    }
  }
}
