import { Injectable, isDevMode } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { BossDYContextType, BossDYWindow, BossDynamicYieldEvent } from './model';
import { UCEvent } from '../../shared/models';

@Injectable({ providedIn: 'root' })
export class BossDynamicYieldService {
  private usercentricsDynamicYieldName = 'Dynamic Yield';

  constructor(private winRef: WindowRef) {
    this.watchUsercentricsChanges();
  }

  triggerEvent(event: BossDynamicYieldEvent): void {
    if (this.winRef.isBrowser()) {
      const dyWindow: BossDYWindow = this.getWindow();

      if (!dyWindow?.DY) return;

      dyWindow.DY.API('event', event);

      if (isDevMode()) {
        console.log(`⭐️ Pushing the following event to Dynamic Yield: `, event);
      }
    }
  }

  private setDynamicYieldConsent(value: boolean): void {
    if (this.winRef.isBrowser()) {
      const dyWindow: BossDYWindow = this.getWindow();

      if (!dyWindow?.DY) return;
      if (!dyWindow?.DYO) return;

      dyWindow.DY.UserActiveConsent = {
        accepted: value,
      };

      dyWindow.DYO.ActiveConsent.updateConsentAcceptedStatus(value);
    }
  }

  private watchUsercentricsChanges(): void {
    if (this.winRef.isBrowser()) {
      window.addEventListener('ucEvent', (e: UCEvent) => {
        if (e.detail?.event == 'consent_status') {
          const dynamicYieldConsent = !!e.detail?.[this.usercentricsDynamicYieldName];

          this.setDynamicYieldConsent(dynamicYieldConsent);
        }
      });
    }
  }

  private getWindow(): BossDYWindow {
    return window as unknown as BossDYWindow;
  }
}
