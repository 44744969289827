export interface BossWindowObject extends Window {
  UC_UI: any;
}

export interface UCEvent extends Event {
  detail: any;
}

export enum BossStatusLabel {
  SUCCESS = 'success',
  DANGER = 'danger',
  INFO = 'info',
  WARNING = 'warning',
}

export type BossStatusLabelType = typeof BossStatusLabel[keyof typeof BossStatusLabel] | undefined;
export enum PictureTextRatio {
  P50T50 = 'P50T50',
  P30T70 = 'P30T70',
  P70T30 = 'P70T30',
}

export enum BossDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
