import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { BossCategory } from '../../models';
import { OccCategoryAdapter } from './occ-category-adapter';
import { PageContext, RoutingService } from '@spartacus/core';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OccCategoryService {
  private pageContextId = '';
  private category$ = new ReplaySubject<BossCategory>(1);

  constructor(private occCategoryAdapter: OccCategoryAdapter, private routingService: RoutingService) {}

  onCategory(): Observable<BossCategory> {
    return this.routingService.getPageContext().pipe(
      map((pageContext: PageContext) => pageContext.id),
      switchMap((pageId: string) => {
        this.next(pageId);
        return this.category$.asObservable();
      }),
    );
  }

  next(pageId: string): void {
    if (pageId !== this.pageContextId) {
      this.pageContextId = pageId;
      this.occCategoryAdapter.loadBossCategory(pageId).subscribe((category: BossCategory) => {
        this.category$.next(category);
      });
    }
  }
}
