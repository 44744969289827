import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { SortByPipe } from '../../shared/pipes/sort-by.pipe';
import { BossNavigationComponent } from './navigation.component';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossNavigationComponent, SortByPipe],
  imports: [
    CommonModule,
    RouterModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        NavigationBarCollectionComponent: {
          component: BossNavigationComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [BossNavigationComponent, SortByPipe],
})
export class BossNavigationModule {}
