<!-- TODO: Add translations -->
<div class="d-block">
  <h2 class="boss-h4 mb-4">PLZ eingeben</h2>
  <p>Bitte gib hier deine Postleitzahl an, damit wir dir eine genaue Einschätzung der Lieferdauer geben können.</p>
</div>

<form>
  <div class="boss-form-element">
    <input
      #zipCode
      [(ngModel)]="postcode"
      id="hybridDeliveryForm-zipCode"
      class="boss-form-control"
      placeholder="{{ 'generalForm.postalCode.labelMandatory' | cxTranslate }}"
      name="zipCode"
      type="text"
      pattern="[0-9]{5}"
      minlength="5"
      maxlength="5"
      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
    />
  </div>
  <div class="boss-form-actions justify-content-center mt-4">
    <button
      [disabled]="!validPostCode || isLoading"
      (click)="submitPostCode(zipCode.value)"
      class="boss-btn boss-btn-magenta"
      type="submit"
      >Übernehmen
    </button>
  </div>
</form>
