import { Injectable } from '@angular/core';
import { Converter, Occ, OccConfig, Product } from '@spartacus/core';
import { BossProductVariantColor } from '../models';
import { Images } from '@spartacus/core/src/model/image.model';

@Injectable({ providedIn: 'root' })
export class BossProductHoverImageNormalizer implements Converter<Occ.Product, Product> {
  constructor(private config: OccConfig) {}

  convert(source: Occ.Product, target?: Product): Product {
    // @ts-ignore
    const { hoverImage, allVariants } = source;

    if (target === undefined) {
      target = { ...(source as any) };
    }

    if (hoverImage) {
      target.hoverImage = this.normalize(hoverImage);
    }

    if (allVariants?.colorVariants) {
      target.allVariants = {
        ...allVariants,
        colorVariants: this.normalizeColorVariants(allVariants.colorVariants),
      };
    }

    return target;
  }

  normalize(source: Occ.Image[]): Images {
    const images = {};
    if (source) {
      for (const image of source) {
        const isList = image.hasOwnProperty('galleryIndex');
        if (!images.hasOwnProperty(image.imageType)) {
          images[image.imageType] = isList ? [] : {};
        }

        let imageContainer;
        if (isList && !images[image.imageType][image.galleryIndex]) {
          images[image.imageType][image.galleryIndex] = {};
        }

        if (isList) {
          imageContainer = images[image.imageType][image.galleryIndex];
        } else {
          imageContainer = images[image.imageType];
        }

        const targetImage = { ...image };
        targetImage.url = this.normalizeImageUrl(targetImage.url);
        imageContainer[image.format] = targetImage;
      }
    }
    return images;
  }

  normalizeColorVariants(colorVariants: BossProductVariantColor[]): BossProductVariantColor[] {
    return colorVariants.map((variant: BossProductVariantColor) => {
      return {
        ...variant,
        product: {
          ...variant.product,
          images: this.normalize(variant.product.images as unknown as Images[]),
          hoverImage: this.normalize(variant.product.hoverImage as unknown as Images[]),
        },
      };
    });
  }

  /**
   * Traditionally, in an on-prem world, medias and other backend related calls
   * are hosted at the same platform, but in a cloud setup, applications are
   * typically distributed cross different environments. For media, we use the
   * `backend.media.baseUrl` by default, but fallback to `backend.occ.baseUrl`
   * if none provided.
   */
  private normalizeImageUrl(url: string): string {
    if (new RegExp(/^(http|data:image|\/\/)/i).test(url)) {
      return url;
    }
    return (this.config.backend.media.baseUrl || this.config.backend.occ.baseUrl || '') + url;
  }
}
