<div class="boss-dropdown" [class.dropdown-disabled]="isDisabled">
  <label *ngIf="label">{{ label }}</label>

  <div [class.dropdown-container-disabled]="isDisabled" class="dropdown-container">
    <button
      class="dropbtn small-copy mb-0 align-items-center"
      type="button"
      (click)="toggleList()"
      [attr.aria-label]="'common.toggle' | cxTranslate"
    >
      {{ selection || placeholder }}
      <i
        bossIcon
        class="ml-1 ml-md-3"
        [type]="showContent ? bossIconConfig.CARET_UP : bossIconConfig.CARET_DOWN"
        aria-hidden="true"
      ></i>
    </button>
  </div>

  <div #dropdownContent *ngIf="!isDisabled" class="dropdown-content small-copy" [ngClass]="showContent && 'show'">
    <a
      *ngIf="showEmpty"
      class="small-copy"
      (click)="onClick(emptyValue, $event)"
      [ngClass]="{ selected: selectedValue === emptyValue, 'have-special': !nothingSpecial }"
    >
      <label *ngIf="useRadio">
        <input [checked]="selectedValue === emptyValue" class="boss-form-control-radio" type="radio" />
        <span class="boss-form-check-label">{{ placeholder }}</span>
      </label>
      <span *ngIf="nothingSpecial">{{ placeholder }}</span>
    </a>

    <a
      class="small-copy"
      *ngFor="let item of displayList | keyvalue: (orderByKeys ? keyOrder : return)"
      (click)="onClick(item.key, $event)"
      [ngClass]="{ selected: selectedValue === item.key, 'have-special': !nothingSpecial }"
    >
      <label *ngIf="useRadio">
        <input [checked]="selectedValue === item.key" class="boss-form-control-radio" type="radio" />
        <span class="boss-form-check-label">{{ item.value }}</span>
      </label>
      <span *ngIf="nothingSpecial">{{ item.value }}</span>
    </a>
  </div>
</div>
