import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  UserIdService,
  WindowRef,
} from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { BossBaseStore } from 'src/app/spartacus/shared/models';
import { BossPointOfService } from 'src/app/spartacus/shared/models/store.models';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { BOSS_BASE_STORE_DATA, BossTransferStateService } from '../../../shared/services/boss-transfer-state';

@Injectable({
  providedIn: 'root',
})
export class BossMyMarketService implements OnDestroy {
  storeNotAvailable = false;

  private storageKey = 'boss_mymarket';

  private subscription = new Subscription();

  private marketSaved$: BehaviorSubject<void> = new BehaviorSubject(null);

  private pointOfService$: Observable<BossPointOfService>;

  constructor(
    private winRef: WindowRef,
    private http: HttpClient,
    private userIdService: UserIdService,
    private occEndpoints: OccEndpointsService,
    private router: Router,
    private globalMessage: GlobalMessageService,
    private bossTransferState: BossTransferStateService,
  ) {
    this.pointOfService$ = this.http.get<BossBaseStore>(this.occEndpoints.buildUrl('baseStores')).pipe(
      map((data: BossBaseStore) => data.store || {}),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.marketSaved$.complete();
  }

  saveMyMarket(pos: BossPointOfService): void {
    this.subscription.add(
      this.userIdService
        .getUserId()
        .pipe(take(1))
        .subscribe((userId: string) => {
          if (userId === 'anonymous') {
            this.saveAnonymousMyMarket(pos);
          } else {
            this.saveRegisteredMyMarket(pos, userId);
          }
          this.router.navigate([pos.url]);
          this.globalMessage.add({ key: 'storeFinder.newMarketMessage' }, GlobalMessageType.MSG_TYPE_INFO);
          this.marketSaved$.next();
        }),
    );
  }

  getMyMarket(): Observable<BossPointOfService> {
    return combineLatest([this.userIdService.getUserId(), this.marketSaved$]).pipe(
      switchMap(([userId]) => {
        if (userId === 'anonymous') {
          return this.getAnonymousMyMarket();
        } else {
          return this.getRegisteredMyMarket(userId);
        }
      }),
    );
  }

  changestoreAvailable(isAvailable: boolean): void {
    this.storeNotAvailable = isAvailable;
  }

  getDefaultPointOfService(): Observable<BossPointOfService> {
    return this.pointOfService$;
  }

  private saveMarketInStorage(pos: BossPointOfService): void {
    if (this.winRef.isBrowser()) {
      this.winRef.sessionStorage?.setItem(this.storageKey, JSON.stringify(pos));
    }
  }

  private getAnonymousMyMarket(): Observable<BossPointOfService> {
    const anonymousMarket = this.winRef.sessionStorage?.getItem(this.storageKey) || '';

    if (this.winRef.isBrowser() && anonymousMarket) {
      return of(JSON.parse(anonymousMarket));
    } else {
      const baseStoreTransferState = this.bossTransferState.get<BossPointOfService>(BOSS_BASE_STORE_DATA);

      return (
        baseStoreTransferState ||
        this.getDefaultPointOfService().pipe(
          tap((data: BossPointOfService) => {
            this.bossTransferState.set<BossPointOfService>(BOSS_BASE_STORE_DATA, data);
          }),
        )
      );
    }
  }

  private saveAnonymousMyMarket(pos: BossPointOfService): void {
    this.saveMarketInStorage(pos);
  }

  private saveRegisteredMyMarket(pos: BossPointOfService, userId: string): void {
    this.saveMarketInStorage(pos);
    const url = this.occEndpoints.buildUrl(`/users/${userId}/myMarket/${pos.name}`);
    this.http.post(url, null).subscribe({ error: (httpError) => console.error(httpError) });
  }

  private getRegisteredMyMarket(userId: string): Observable<BossPointOfService> {
    const url = this.occEndpoints.buildUrl(`/users/${userId}/myMarket?fields=FULL`);
    return this.http.get(url);
  }
}
