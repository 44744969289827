import { Injectable } from '@angular/core';
import { BasePageMetaResolver, BreadcrumbMeta, isNotUndefined, PageRobotsMeta } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BossBasePageMetaResolver extends BasePageMetaResolver {
  private bossSiteName = 'Möbel Boss';

  resolveTitle(): Observable<string> {
    return this.cmsService.getCurrentPage().pipe(
      filter(isNotUndefined),
      map((page) => {
        const label = page.title ? page.title : page.name;
        return label + ' | ' + this.bossSiteName;
      }),
    );
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    const url = this.router.url;
    const queryPos = url.indexOf('?');

    if (queryPos > 0) {
      return of([PageRobotsMeta.NOINDEX, PageRobotsMeta.NOFOLLOW]);
    }
    return this.robots$;
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[] | undefined> {
    return this.cmsService.getCurrentPage().pipe(
      filter(isNotUndefined),
      map((page) => {
        if (!page?.title && page?.name === 'Nicht gefunden') {
          return undefined;
        }
        const breadcrumbs = [];
        breadcrumbs.push({
          label: page.title ? page.title : page.name,
          link: 'https://moebel-boss.de',
        } as BreadcrumbMeta);
        return breadcrumbs;
      }),
    );
  }
}
