import { NgModule } from '@angular/core';
import { TagManagementFeatureModule } from './features/tracking/tag-management-feature.module';
import { PersonalizationFeatureModule } from './features/tracking/personalization-feature.module';
import {
  CartPageEventModule,
  HomePageEventModule,
  NavigationEventModule,
  ProductPageEventModule,
} from '@spartacus/storefront';
import { BossCheckoutFeatureModule } from './features/checkout/boss-checkout-feature.module';
import { CommonModule } from '@angular/common';
import { BossStorefrontComponentModule } from './features/boss-storefront/boss-storefront.module';
import { BossComponentsFeatureModule } from './features/components/boss-components-feature.module';
import { BossOrderFeatureModule } from './features/order/boss-order-feature.module';
import { RebrushTestFlexFeatureModule } from './features/rebrush-test-flex/rebrush-test-flex-feature.module';
import { BossWishListFeatureModule } from './features/wishlist/boss-wish-list-feature.module';
import { BossStoreFinderFeatureModule } from './features/storefinder/boss-store-finder-feature.module';
import { BossAddressFeatureModule } from './features/address/boss-address-feature.module';
import { BossScrollPositionFeatureModule } from './features/scroll-position/boss-scroll-position-feature.module';
import { BossProductDetailsPageFeatureModule } from './features/product-details-page/boss-product-details-page-feature.module';
import { BossCartFeatureModule } from './features/cart/boss-cart-feature.module';
import { BossUserProfileFeatureModule } from './features/user-profile/boss-user-profile-feature.module';
import { BossHamburgerMenuModule } from './features/hamburger-menu/boss-hamburger-menu.module';
import { BossMiniCartModule } from './features/mini-cart/mini-cart.module';
import { BossNavigationModule } from './features/navigation/navigation.module';
import { BossSearchBoxModule } from './features/search-box/search-box.module';
import { BossSiteLogoComponentModule } from './features/site-logo/site-logo.module';
import { BossUserAccountModule } from './features/user-account/user-account.module';
import { BossWishlistIconComponentModule } from './features/wishlist-icon/wishlist-icon.module';
import { BossMyMarketModule } from './features/my-market/my-market.module';
import { BossMainStageModule } from './features/boss-main-stage/boss-main-stage.module';
import { BossWeeklyOfferComponentModule } from './features/weekly-offer/weekly-offer.module';
import { BossHeadlineAndIntroModule } from './features/headline-and-intro/headline-and-intro.module';
import { BossSalesBannerComponentModule } from './features/sales-banner/boss-sales-banner.module';
import { BossContentStageModule } from './features/content-stage/boss-content-stage.module';
import { ImageMoodHalfModule } from './features/image-mood/image-mood-half/image-mood-half.module';
import { BossThreeColumnsModule } from './features/columns/three-column/three-column.module';
import { TeaserNewsletterComponentModule } from './features/teaser-newsletter/teaser-newsletter.component.module';
import { BossParagraphComponentModule } from './features/paragraph/paragraph.module';
import { ImageMoodTextModule } from './features/image-mood/image-mood-text/image-mood-text.module';
import { BossImageMoodSliderModule } from './features/image-mood/boss-image-mood-slider/boss-image-mood-slider.module';
import { BossTwoColumnsModule } from './features/columns/two-column/two-column.module';
import { BossFooterMiddleComponentModule } from './features/footer/footer-middle/footer-middle.module';
import { BossFooterNewsletterComponentModule } from './features/footer/footer-newsletter/footer-newsletter.module';
import { BossFooterPaymentComponentModule } from './features/footer/footer-payment/footer-payment.module';
import { BossFooterSiegelComponentModule } from './features/footer/footer-siegel/footer-siegel.module';
import { BossFooterTopComponentModule } from './features/footer/footer-top/footer-top.module';
import { BossFooterTrustedComponentModule } from './features/footer/footer-trusted/footer-trusted.module';
import { BossProductImagesComponentModule } from './features/product-details-page/components/product-images/product-images.module';
import { BossProductDescriptionComponentModule } from './features/product-details-page/components/product-description/product-description.module';
import { BossProductLastViewedComponentModule } from './features/product-last-viewed/product-last-viewed.module';
import { BossProductPriceComponentModule } from './features/product/product-price/product-price.module';
import { BossProductPriceTopDownComponentModule } from './features/product/product-price-top-down/product-price-top-down.module';
import { BossProductReferencesComponentModule } from './features/product-references/product-references.module';
import { BossProductStarRatingComponentModule } from './features/product/product-star-rating/product-star-rating.module';
import { BossProductVariantsComponentModule } from './features/product/product-variants/product-variants.module';
import { ProductHighlightTileModule } from './features/product-highlight-tile/product-highlight-tile.module';
import { BossProductListComponentModule } from './features/product/product-list/product-list.module';
import { BossProductFacetsComponentModule } from './features/product-facet-navigation/product-facet-navigation.module';
import { BossGlobalMessageModule } from './features/boss-global-message/boss-global-message.module';
import { BossLinkModule } from './features/boss-link/boss-link.module';
import { BossBackToTopModule } from './shared/components/back-to-top/back-to-top.module';
import { BossBreadcrumbModule } from './features/breadcrumb/breadcrumb.module';
import { BossCategoryTreeModule } from './features/category-tree/category-tree.module';
import { BossBannerAutomaticCarouselComponentModule } from './features/banner-automatic-carousel/banner-automatic-carousel.module';
import { BossResetPasswordComponentComponentModule } from './features/reset-password-component/reset-password-component.module';
import { BossNewCustomerLoginComponentModule } from './features/new-customer-login/new-customer-login.module';
import { BossReturningCustomerLoginComponentModule } from './features/returning-customer-login/returning-customer-login.module';
import { BossOrderDetailShippingModule } from './features/order-detail-shipping/order-detail-shipping.module';
import { BossUserAccountPageTitleModule } from './features/boss-user-account/boss-user-account-page-title/boss-user-account-page-title.module';
import { BossForgotPasswordDialogComponentModule } from './shared/components/forgot-password-dialog/forgot-password-dialog.module';
import { BossForgotPasswordFormComponentModule } from './features/forgot-password-form/forgot-password-form.module';
import { BossAccountMarketContactModule } from './features/account-market-contact/account-market-contact.module';
import { BossPaginationComponentModule } from './features/pagination/boss-pagination.module';
import { BossPillsModule } from './shared/components/pills/boss-pills.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { BossKitchenPlannerFeatureModule } from './features/boss-kitchen-planner/boss-kitchen-planner-feature.module';

// Header - eager for good initial load
const header = [
  BossHamburgerMenuModule,
  BossMiniCartModule,
  BossNavigationModule,
  BossSearchBoxModule,
  BossSiteLogoComponentModule,
  BossUserAccountModule,
  BossWishlistIconComponentModule,
  BossMyMarketModule,
];

// Homepage - eager for good initial load
const homepage = [
  BossMainStageModule,
  BossWeeklyOfferComponentModule,
  BossHeadlineAndIntroModule,
  BossSalesBannerComponentModule,
  BossContentStageModule,
];

// Shared lazy loaded components
const shared = [
  ImageMoodHalfModule,
  BossThreeColumnsModule,

  //HOMEPAGE
  TeaserNewsletterComponentModule,
  BossParagraphComponentModule,
  ImageMoodTextModule,
  BossImageMoodSliderModule,
  BossTwoColumnsModule,
];

// Footer lazy loaded modules
const footer = [
  BossFooterMiddleComponentModule,
  BossFooterNewsletterComponentModule,
  BossFooterPaymentComponentModule,
  BossFooterSiegelComponentModule,
  BossFooterTopComponentModule,
  BossFooterTrustedComponentModule,
  BossKitchenPlannerFeatureModule,
];

const productDetailsPage = [BossProductImagesComponentModule, BossProductDescriptionComponentModule];

const product = [
  BossProductLastViewedComponentModule,
  BossProductPriceComponentModule,
  BossProductPriceTopDownComponentModule,
  BossProductReferencesComponentModule,
  BossProductStarRatingComponentModule,
  BossProductVariantsComponentModule,

  ProductHighlightTileModule,
];

const productList = [BossProductListComponentModule, BossProductFacetsComponentModule];

// The rest of the modules
const modules = [
  BossStorefrontComponentModule,
  BossGlobalMessageModule,
  BossLinkModule,
  BossBackToTopModule,
  BossBreadcrumbModule,
  BossCategoryTreeModule,
  BossBannerAutomaticCarouselComponentModule,

  BossResetPasswordComponentComponentModule,
  BossNewCustomerLoginComponentModule,
  BossReturningCustomerLoginComponentModule,
  BossOrderDetailShippingModule,
  BossUserAccountPageTitleModule,
  BossForgotPasswordDialogComponentModule,
  BossForgotPasswordFormComponentModule,
  BossAccountMarketContactModule,
  BossPaginationComponentModule,
  BossPillsModule,
];

@NgModule({
  imports: [
    CommonModule,
    TagManagementFeatureModule,
    PersonalizationFeatureModule,
    // Page Events
    NavigationEventModule,
    HomePageEventModule,
    CartPageEventModule,
    ProductPageEventModule,
    BossCheckoutFeatureModule,
    BossComponentsFeatureModule,
    BossOrderFeatureModule,
    BossWishListFeatureModule,
    BossStoreFinderFeatureModule,
    BossProductDetailsPageFeatureModule,
    BossAddressFeatureModule,
    BossScrollPositionFeatureModule,
    BossCartFeatureModule,
    BossUserProfileFeatureModule,
    SmartEditFeatureModule,
    ...header,
    ...footer,
    ...homepage,
    ...modules,
    ...shared,
    ...product,
    ...productDetailsPage,
    productList,
    RebrushTestFlexFeatureModule,
  ],
  exports: [BossStorefrontComponentModule],
})
export class SpartacusFeaturesModule {}
