import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { BossSearchBoxComponent } from './search-box.component';
import { BossSafePipeModule } from '../../shared/pipes/boss-safe-pipe.module';
import { BossIconDirectiveModule } from '../../shared/directives/icon/boss-icon.directive.module';

@NgModule({
  declarations: [BossSearchBoxComponent],
  imports: [
    CommonModule,
    MediaModule,
    UrlModule,
    RouterModule,
    I18nModule,
    BossIconDirectiveModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SearchBoxComponent: {
          component: BossSearchBoxComponent,
        },
      },
    } as CmsConfig),
    BossSafePipeModule,
  ],
  exports: [BossSearchBoxComponent],
})
export class BossSearchBoxModule {}
