<ng-container *ngIf="items?.length">
  <div [ngClass]="[styleClass, 'main-stage']">
    <ng-container *ngIf="items?.length === 1; else carousel">
      <ng-container *ngIf="items[0] as item">
        <div class="main-stage-single-item">
          <ng-container *ngTemplateOutlet="carouselBody; context: { $implicit: item, index: 0 }"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #carousel>
      <swiper [config]="swiperConfig">
        <ng-template swiperSlide *ngFor="let item of items; index as i; trackBy: trackById">
          <ng-container *ngTemplateOutlet="carouselBody; context: { $implicit: item, index: i }"></ng-container>
        </ng-template>
      </swiper>
    </ng-template>
  </div>
</ng-container>

<ng-template #carouselBody let-item let-i="index">
  <div class="main-stage-body">
    <div class="main-stage-underlay">
      <div class="main-stage-image">
        <img
          bossMedia
          [isAboveTheFold]="i === 0"
          [container]="item.media"
          [alt]="item.media?.altText"
          [attr.fetchpriority]="i === 0 ? 'high' : null"
        />
      </div>
    </div>

    <div class="main-stage-overlay">
      <div class="main-stage-text">
        <boss-headline
          [text]="item.bannerHeadline | bossTextLimit: BossStageTextLimitType.HEADLINE"
          [type]="item.headlineType"
          class="main-stage-headline"
        >
        </boss-headline>

        <p class="subtitle">{{ item.text | bossTextLimit: BossStageTextLimitType.COPY_TEXT }}</p>

        <ng-container *ngIf="item.bannerLink?.url as url">
          <cx-generic-link [url]="url">
            <button [ngClass]="[buttonClass, 'boss-btn']">
              {{ item.bannerLink?.linkName | bossTextLimit: BossStageTextLimitType.CTA }}
            </button>
          </cx-generic-link>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
