import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  ActivatedRouterStateSnapshot,
  CurrencyService,
  LanguageService,
  ProductSearchService,
  RouterState,
  RoutingService,
} from '@spartacus/core';
import { ProductListComponentService, SearchCriteria } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BossProductListComponentService extends ProductListComponentService implements OnDestroy {
  defaultPageSize = 60;

  private customSearchCriteria: SearchCriteria | null;

  private onDestroy$ = new Subject<void>();

  constructor(
    productSearchService: ProductSearchService,
    routing: RoutingService,
    activatedRoute: ActivatedRoute,
    currencyService: CurrencyService,
    languageService: LanguageService,
    router: Router,
    private pageSizeStore: Store<any>,
  ) {
    super(productSearchService, routing, activatedRoute, currencyService, languageService, router);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  searchByRouting$: Observable<ActivatedRouterStateSnapshot> = this.routing.getRouterState().pipe(
    distinctUntilChanged((x, y) => {
      return x.state.url === y.state.url;
    }),
    debounceTime(0),
    map((routerState) => (routerState as RouterState).state),
    withLatestFrom(this.pageSizeStore.pipe(select('pageSize'))),
    map(([state, size]: [ActivatedRouterStateSnapshot, any]) => {
      const criteria = !!this.customSearchCriteria
        ? this.customSearchCriteria
        : this.getCriteriaFromRoute(state.params, {
            ...state.queryParams,
            pageSize: size?.pageSize?.pageSize,
          });

      this.search(criteria);

      // Reset the value so we can still use PLP and search page after route change
      this.customSearchCriteria = null;

      return state;
    }),
  );

  search(criteria: SearchCriteria): void {
    const currentPage = criteria.currentPage ?? 1;
    const pageSize = criteria.pageSize;
    const sort = criteria.sortCode;

    this.productSearchService.search(
      criteria.query,
      Object.assign(
        {},
        +currentPage - 1 && { currentPage: +currentPage - 1 },
        pageSize && { pageSize },
        sort && { sort },
      ),
    );
  }

  /**
   * Used to set custom search criteria, which allows product listings outside of PLP and search page to work.
   * Usually search criteria (on PLP and search page) are taken from router info.
   * For pages outside, we need to pass custom criteria, as router has no required information.
   *
   * @param {SearchCriteria} criteria
   */
  setCustomSearchCriteria(criteria: SearchCriteria): void {
    this.customSearchCriteria = criteria;
  }
}
