<div class="row m-0">
  <div class="col-md-6 left-panel pl-0"></div>
  <div class="col-md-6 right-panel">
    <div class="modal-header">
      <img
        src="/assets/img/santandar-logo-small.png"
        alt="Santander Consumer Bank"
        class="header-image"
        loading="lazy"
      />
      <boss-modal-close-button></boss-modal-close-button>
    </div>

    <div class="modal-body">
      <div class="financingCalculator">
        <div id="wrapper">
          <div id="header">
            <h4 class="boss-h4 text-transform-none">{{ 'productDetails.financingCalculator.label' | cxTranslate }}</h4>
            <p class="small-copy description"
              >Details zu Deiner Finanzierung erhälst Du bei Auswahl der entsprechenden Zahlart im Bezahlvorgang.</p
            >
          </div>
          <div [formGroup]="calculator" id="content">
            <div class="row mb-0">
              <div class="col-md-6 col-12">
                <div class="d-flex align-items-center financing-calculator-input-container">
                  <div class="boss-form-element">
                    <label class="boss-form-label" for="calculator-volume">{{
                      'productDetails.financingCalculator.labelValue' | cxTranslate
                    }}</label>
                    <input
                      (change)="calc()"
                      #volume
                      formControlName="volume"
                      id="calculator-volume"
                      class="boss-form-control"
                      type="text"
                    />
                  </div>
                  <span class="financing-calculator-label-symbol small-copy p-0">&nbsp;&euro;</span>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-0 d-flex">
                <div class="boss-form-element">
                  <label class="boss-form-label">{{ 'productDetails.financingCalculator.term' | cxTranslate }}</label>
                  <boss-dropdown
                    [list]="terms"
                    [listLabel]="'name'"
                    [listValue]="'code'"
                    [orderByKeys]="true"
                    [selectedValue]="getTermValue()"
                    [placeholder]="'productDetails.financingCalculator.termPlaceholder' | cxTranslate"
                    (selected)="setTermValue($event)"
                  ></boss-dropdown>
                </div>
              </div>
            </div>
            <div #error id="error" class="caption error">{{
              'productDetails.financingCalculator.errorMessage' | cxTranslate
            }}</div>
          </div>
          <hr class="boss-nude-line" />
          <div id="result">
            <h6 class="boss-h6">{{ 'productDetails.financingCalculator.detail' | cxTranslate }}</h6>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="d-flex align-items-center financing-calculator-input-container">
                  <div class="boss-form-element">
                    <label class="boss-form-label" for="calculator-netto">{{
                      'productDetails.financingCalculator.netto' | cxTranslate
                    }}</label>
                    <input #netAmount id="calculator-netto" class="boss-form-control" type="text" readonly disabled />
                  </div>
                  <span class="financing-calculator-label-symbol small-copy p-0">&nbsp;&euro;</span>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="d-flex align-items-center financing-calculator-input-container">
                  <div class="boss-form-element">
                    <label class="boss-form-label" for="calculator-interestAmount">{{
                      'productDetails.financingCalculator.zins' | cxTranslate
                    }}</label>
                    <input
                      #interestAmount
                      id="calculator-interestAmount"
                      class="boss-form-control"
                      type="text"
                      readonly
                      disabled
                    />
                  </div>
                  <span class="financing-calculator-label-symbol small-copy p-0">&nbsp;&euro;</span>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="d-flex align-items-center financing-calculator-input-container">
                  <div class="boss-form-element">
                    <label class="boss-form-label" for="calculator-rateAmount"
                      ><span #rateAmount class="pl-0">0</span>
                      {{ 'productDetails.financingCalculator.rate' | cxTranslate }} &agrave;:</label
                    >
                    <input
                      #rateLevel
                      id="calculator-rateAmount"
                      class="boss-form-control"
                      type="text"
                      readonly
                      disabled
                    />
                  </div>
                  <span class="financing-calculator-label-symbol small-copy p-0">&nbsp;&euro;</span>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="d-flex align-items-center financing-calculator-input-container">
                  <div class="boss-form-element">
                    <label class="boss-form-label" for="calculator-grossAmount">{{
                      'productDetails.financingCalculator.total' | cxTranslate
                    }}</label>
                    <input
                      #grossAmount
                      id="calculator-grossAmount"
                      class="boss-form-control"
                      type="text"
                      readonly
                      disabled
                    />
                  </div>
                  <span class="financing-calculator-label-symbol small-copy p-0">&nbsp;&euro;</span>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="d-flex align-items-center financing-calculator-input-container">
                  <div class="boss-form-element">
                    <label class="boss-form-label" for="calculator-debitInterest">{{
                      'productDetails.financingCalculator.sollzins' | cxTranslate
                    }}</label>
                    <input
                      #debitInterest
                      id="calculator-debitInterest"
                      class="boss-form-control"
                      type="text"
                      readonly
                      disabled
                    />
                  </div>
                  <span class="financing-calculator-label-symbol small-copy p-0">&#160;&#37;</span>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="d-flex align-items-center financing-calculator-input-container">
                  <div class="boss-form-element">
                    <label class="boss-form-label" for="calculator-effInterest">{{
                      'productDetails.financingCalculator.annualInterest' | cxTranslate
                    }}</label>
                    <input
                      #effInterest
                      id="calculator-effInterest"
                      class="boss-form-control"
                      type="text"
                      readonly
                      disabled
                    />
                  </div>
                  <span class="financing-calculator-label-symbol small-copy p-0">&#160;&#37;</span>
                </div>
              </div>
            </div>
          </div>
          <div id="disclaimer" class="pl-0">
            <p class="caption">{{ 'productDetails.financingCalculator.disclaimer' | cxTranslate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
