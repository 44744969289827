<ng-container *ngIf="componentData as data">
  <div
    class="boss-sales-banner copy bg-magenta"
    [ngStyle]="{
      'background-color': data.backgroundColor
    }"
  >
    <ng-container *ngIf="data.text">
      <div class="textComponent" [ngStyle]="{ color: data.textColor }">
        <a class="boss-btn-as-link" [routerLink]="data.urlLink | cxUrl">
          <span>
            <ng-container *ngIf="isMobile; else desktop">
              {{ data.mobileText }}
            </ng-container>

            <ng-template #desktop>
              {{ data.text }}
            </ng-template>
          </span>

          <ng-container *ngIf="data.countDown">
            <div class="timer text-white" [ngStyle]="{ color: data.textColor }">
              <div class="timer-remain">
                <div class="timer-remain-time">{{ timeLeft.day }}</div>
                <div class="timer-remain-unit">Tage</div>
              </div>
              <div class="timer-remain">
                <div class="timer-remain-time">{{ timeLeft.h }}</div>
                <div class="timer-remain-unit">Stunden</div>
              </div>
              <div class="timer-remain">
                <div class="timer-remain-time">{{ timeLeft.min }}</div>
                <div class="timer-remain-unit">Minuten</div>
              </div>
              <div class="timer-remain">
                <div class="timer-remain-time">{{ timeLeft.sec }}</div>
                <div class="timer-remain-unit">Sekunden</div>
              </div>
            </div>
          </ng-container>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="data.salesCode">
      <div class="promo-code">
        <span
          class="text-slate bg-nude"
          [ngStyle]="{
            color: data.salesCodeTextColor,
            'background-color': data.salesCodeBackgroundColor
          }"
        >
          {{ data.salesCode }}
        </span>

        <button class="boss-btn-as-link" (click)="copyToClipboard(data.salesCode, $event)" type="button">
          <ng-container *ngIf="!codeCopied; else copied">
            <i bossIcon [type]="bossIconConfig.FILE_COPY" aria-hidden="true"></i>
            <span>Code kopieren</span>
          </ng-container>

          <ng-template #copied>
            <i bossIcon [type]="bossIconConfig.CHECKBOX_CIRCLE_LINE" aria-hidden="true"></i>
            <span>Kopiert</span>
          </ng-template>
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>
