export const storeFinder = {
  storeFinder: {
    goToContactForm: 'Kontakt aufnehmen',
    goToProspects: 'Prospekt ansehen',
    search: 'Möbelhaus finden',
    newMarketMessage: 'Der Markt wird als neuer Markt hinterlegt.',
    nostorefound: {
      headline: 'Information',
      text: 'Es wurden leider keine Märkte gefunden, daher zeigen wir Ihnen alle Filialen.',
    },
    nearby: {
      stores: 'Dein Markt vor Ort',
      description:
        'Wir sind in vielen unserer Märkte wieder für Sie da! Melden Sie sich einfach im Markt Ihrer Wahl per Mail oder Telefon - unsere Mitarbeiter informieren Sie gerne über die Möglichkeiten eines Besuchs im Markt. Die Kontaktdaten Ihres Marktes finden Sie ganz einfach, wenn Sie die gewünschte PLZ oder Stadt im Suchfeld eingeben:',
    },
    geolocation: {
      disabled:
        'Leider funktioniert die Geolokalisierung nicht. Sie haben die Browserfunktion zur Standortabfrage deaktiviert. Gehen Sie in Ihre Browsereinstellungen, um diese Funktion wieder zu aktivieren.',
    },
    query: {
      label: 'PLZ oder Stadt',
      placeholder: 'PLZ oder Stadt',
    },
    pagination: {
      searchText: 'Märkte gefunden',
      singleResult: 'Markt gefunden',
    },
    table: {
      openingDays: 'Öffnungszeiten',
      time: 'Uhr',
      open: 'Jetzt geöffnet',
      storageSale: 'Warenausgabe von {{start}}-{{end}} Uhr',
      closed: 'zur Zeit geschlossen',
      telephone: 'Telefon',
      fax: 'Fax',
      servicephone: 'Service-Center',
      email: 'E-Mail',
      saveMarket: 'Als “Mein Markt” speichern',
      viewProspect: 'Prospekt ansehen',
      marketpage: 'Mehr Marktdetails',
    },
    details: {
      header: 'WILLKOMMEN IN IHREM MÖBELHAUS {{shortName}}',
      headerShort: 'MÖBELHAUS {{shortName}}',
      storageSale: 'Öffnungszeiten unserer Warenausgabe',
      openingSpecialDays: 'Sonderöffnungszeiten',
      features: 'Merkmale',
      services: 'Services',
      news: 'Aktuelles',
      calculateRoute: 'Route berechnen',
      saveMarket: 'Als "Mein Markt" speichern',
    },
    header: {
      address: 'Adresse',
      prospect: 'Aktueller Prospekt',
      route: 'Anfahrt berechnen',
      info: 'Unsere Mitarbeiter bieten Informationen und Beratung in verschiedenen Sprachen an. Sprechen Sie uns an.',
      marketpage: 'Zum Möbelhaus',
      storefinder: 'Möbelhaus ändern',
      closingText: 'heute geöffnet bis',
      closingHour: '{{end}} Uhr',
    },
    openUntil: 'Geöffnet bis',
    closed: 'Geschlossen',
    call: 'Anrufen',
    getDirections: 'Wegbeschreibung',
    listView: 'Listenansicht',
    mapView: 'Kartenansicht',
    noStoresFound: 'Keine Filiale gefunden.',
    storeHours: 'Öffnungszeiten',
    storeFeatures: 'Features',
    fromStoresFound: 'der {{ count }} Filiale gefunden',
    fromStoresFound_plural: 'der {{ count }} Filialen gefunden',
    findStore: 'Filiale suchen',
    useMyLocation: 'Mein Standort verwenden',
    viewAllStores: 'Alle Filialen anzeigen',
    contactUs: 'Uns kontaktieren',
    searchBox: 'PLZ, Stadt oder Adresse eingeben',
    backToList: 'Zurück zur Liste',
    noStoresMessage: 'Keine Filiale in Datenbank gefunden...',
    geolocationNotEnabled:
      'Auf naheliegende Filialen kann nicht zugegriffen werden, die Geolokalisierung wurde nicht aktiviert',
  },
};
