import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Cart,
  InterceptorUtil,
  OCC_USER_ID_ANONYMOUS,
  OccEndpointsService,
  ProductService,
  USE_CLIENT_TOKEN,
  UserIdService,
  WindowRef,
  getCartIdByUserId,
} from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { BossActiveCartService } from '../../features/product/product-add-to-cart/active-cart.service';
import { BossZipCodeService } from './zip-code/boss-zip-code.service';

@Injectable({
  providedIn: 'root',
})
export class BossStockService {
  private sessionStorage: Storage;

  constructor(
    private bossActiveCartService: BossActiveCartService,
    private userIdService: UserIdService,
    private productService: ProductService,
    private occEndpointsService: OccEndpointsService,
    private http: HttpClient,
    private zipCodeService: BossZipCodeService,
    private winRef: WindowRef,
  ) {
    this.sessionStorage = this.winRef.sessionStorage;
  }

  checkStockByZip(zip: string, quantity: number, productCode: string): Observable<Cart> {
    if (this.sessionStorage && quantity) {
      this.sessionStorage.setItem('qty', quantity.toString());
    }

    if (zip) {
      this.zipCodeService.set(zip);
      this.productService.reload(productCode, 'zipCodeAndQty');
    }

    return zip
      ? this.bossActiveCartService.isStable().pipe(
          filter(Boolean),
          switchMap(() =>
            combineLatest([this.bossActiveCartService.requireLoadedCart(), this.userIdService.takeUserId()]),
          ),
          switchMap(([cartState, userId]) => {
            const cartId = getCartIdByUserId(cartState.value, userId);
            return this.addZipToCart(userId, cartId, zip);
          }),
        )
      : of();
  }

  addZipToCart(userId: string, cartId: string, zipCode: string): Observable<Cart> {
    const url: string = this.occEndpointsService.getUrl(
      'hybridDeliveryZipCode',
      {
        userId,
        cartId,
      },
      { zipCode },
    );

    let headers = new HttpHeaders();

    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return zipCode ? this.http.put<Cart>(url, {}, { headers }) : of();
  }
}
