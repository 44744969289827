<boss-active-facets *ngIf="facetList?.activeFacets" [activeFacets]="facetList.activeFacets"></boss-active-facets>

<div class="cx-page boss-product-list" *ngIf="model; else loading">
  <section class="cx-page-section">
    <ng-container *ngIf="model.products.length > 0; else noProductsFound">
      <div class="cx-sorting top">
        <div class="row boss-product-list--top">
          <div class="col-5 col-md-8 d-flex align-items-center">
            <p class="small-copy-bold mb-0">
              {{ model.pagination.totalResults.toLocaleString() }} {{ 'productList.products' | cxTranslate }}
            </p>
          </div>
          <div class="col-7 col-md-4">
            <div class="form-group cx-sort-dropdown mb-0">
              <boss-dropdown
                placeholder="{{ model.pagination.sort || ('productList.sortByRelevance' | cxTranslate) }}"
                label=""
                [list]="model.sorts"
                [selectedValue]="model.pagination.sort"
                listLabel="name"
                listValue="code"
                (selected)="sortList($event)"
              ></boss-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="cx-product-container">
        <div class="row">
          <boss-product-card
            *ngFor="let product of model.products; let i = index; trackBy: productListTracker"
            [product]="product"
            class="col-6 col-lg-4 boss-product-list--card listing-product"
            [id]="product.code"
            (click)="clickOnProductCard(i)"
          ></boss-product-card>
        </div>
      </div>
      <boss-dialog-wrapper></boss-dialog-wrapper>
      <div class="pagination" aria-label="product list pagination">
        <boss-pagination [pagination]="model.pagination" queryParam="currentPage" [defaultPage]="0"></boss-pagination>
      </div>
    </ng-container>

    <ng-template #noProductsFound>
      <div class="col-12 col-lg-12">
        <div class="cx-sorting top">
          <div class="row">
            <div class="col-12 col-lg-4 ml-auto"></div>
          </div>
        </div>
        <div class="cx-product-container"></div>
        <div class="cx-sorting bottom">
          <div class="row">
            <div class="col-12 col-lg-4 mr-auto"></div>
          </div>
        </div>
      </div>
    </ng-template>
  </section>
</div>

<ng-template #loading>
  <div class="boss-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
