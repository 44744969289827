import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Breadcrumb, RoutingService } from '@spartacus/core';
import { BREAKPOINT, BreakpointService, FacetService } from '@spartacus/storefront';
import { bossIconConfig } from 'src/app/spartacus/shared/utils/boss-icon-config';
import { FacetTypeService } from '../facet-type.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'boss-active-facets[activeFacets]',
  templateUrl: './boss-active-facets.component.html',
  styleUrls: ['./boss-active-facets.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossActiveFacetsComponent implements OnChanges {
  @Input()
  activeFacets!: Breadcrumb[];

  @Input()
  facetName?: string;

  @Input()
  isDialog = false;

  isMobile$: Observable<boolean> = this.breakpointService.isDown(BREAKPOINT.md);
  bossIconConfig = bossIconConfig;

  constructor(
    private facetService: FacetService,
    private facetTypeService: FacetTypeService,
    private routingService: RoutingService,
    private breakpointService: BreakpointService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeFacets) {
      if (this.activeFacets) {
        this.setActiveFacets();
      }
    }
  }

  setActiveFacets(): void {
    this.activeFacets = this.facetName
      ? this.activeFacets.filter((facet: Breadcrumb) => facet.facetName === this.facetName)
      : this.activeFacets;

    this.activeFacets = this.activeFacets.map((facet: Breadcrumb) => {
      return { ...facet, filterType: this.facetTypeService.getFilterType(facet.facetCode) };
    });
  }

  getLinkParams(facet: Breadcrumb): { [key: string]: string } {
    return this.facetService.getLinkParams(facet.removeQuery?.query?.value);
  }

  removeAllFilters(): void {
    this.routingService.go([], {});
  }
}
