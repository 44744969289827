<ng-container *ngIf="componentData.data$ | async as data">
  <ng-container *ngIf="isMobile; else isDesktop">
    <a
      class="search boss-searchbox-link mobile-search-anchor"
      [attr.aria-label]="'search'"
      (click)="toggleMobileSearchOpen()"
      [class.--open]="isOpen"
    >
      <i bossIcon [type]="bossIconConfig.SEARCH" aria-hidden="true"></i>
    </a>
    <div class="search-box" [class.--open]="isOpen">
      <label for="search" class="searchbox">
        <div class="input-group">
          <input
            #searchInput
            type="text"
            id="search"
            autocomplete="off"
            [placeholder]="'bossSearchBox.placeholder' | cxTranslate"
            aria-label="search"
            (focus)="openSearch()"
            (input)="customSearch(data.uid, searchInput.value)"
            (blur)="close($event)"
            class="searchbar"
            (keydown.escape)="close($event); closeMobileSearch()"
            (keydown.enter)="
              addElementToLastSearch(searchInput.value);
              launchSearchResult($event, searchInput.value);
              close($event);
              closeMobileSearch();
              clear(searchInput)
            "
            (keydown.arrowup)="focusPreviousChild($event)"
            (keydown.arrowdown)="focusNextChild($event)"
          />
          <div class="input-group-append">
            <i
              bossIcon
              *ngIf="searchInput.value.length > 0"
              [type]="bossIconConfig.CLOSE"
              (click)="clearSearch()"
              (mousedown)="preventDefault($event)"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <button
          [attr.aria-label]="'common.search' | cxTranslate"
          class="search-btn"
          (mousedown)="
            preventDefault($event);
            addElementToLastSearch(searchInput.value);
            launchSearchResult($event, searchInput.value);
            close($event);
            closeMobileSearch();
            clear(searchInput)
          "
          (keydown.enter)="
            addElementToLastSearch(searchInput.value);
            launchSearchResult($event, searchInput.value);
            close($event);
            closeMobileSearch();
            clear(searchInput)
          "
          tabindex="0"
        >
          <i bossIcon class="search-icon" [type]="bossIconConfig.SEARCH" aria-hidden="true"></i>
        </button>
      </label>
    </div>
  </ng-container>
  <!-- Desktop styles for searchbar -->
  <ng-template #isDesktop>
    <div class="search-box desktop">
      <label for="searchDesktop" class="searchbox">
        <div class="input-group">
          <input
            #searchInput
            type="text"
            id="searchDesktop"
            autocomplete="off"
            [placeholder]="'bossSearchBox.placeholder' | cxTranslate"
            aria-label="search"
            (focus)="openSearch()"
            (blur)="close($event)"
            (input)="customSearch(data.uid, searchInput.value)"
            class="searchbar"
            (keydown.escape)="close($event); closeMobileSearch()"
            (keydown.enter)="
              addElementToLastSearch(searchInput.value);
              launchSearchResult($event, searchInput.value);
              close($event);
              closeMobileSearch();
              clear(searchInput)
            "
            (keydown.arrowup)="focusPreviousChild($event)"
            (keydown.arrowdown)="focusNextChild($event)"
          />
          <div class="input-group-append">
            <i
              bossIcon
              *ngIf="searchInput.value.length > 0"
              [type]="bossIconConfig.CLOSE"
              (click)="clearSearch()"
              (mousedown)="preventDefault($event)"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <button
          aria-label="search"
          class="search-btn"
          (mousedown)="
            preventDefault($event);
            addElementToLastSearch(searchInput.value);
            launchSearchResult($event, searchInput.value);
            close($event);
            closeMobileSearch();
            clear(searchInput)
          "
          (keydown.enter)="
            addElementToLastSearch(searchInput.value);
            launchSearchResult($event, searchInput.value);
            close($event);
            closeMobileSearch();
            clear(searchInput)
          "
          tabindex="0"
        >
          <i bossIcon class="search-icon" [type]="bossIconConfig.SEARCH" aria-hidden="true"></i>
        </button>
      </label>
    </div>
  </ng-template>
  <!-- Search Results -->
  <div class="results mod-quick-search__flyout">
    <div class="row" (scroll)="handleResultsScroll($event)">
      <ng-container *ngIf="!bossAutoCompleteResult$">
        <div *ngIf="lastSearchedResults?.length" class="col-lg-3 col-12">
          <div class="searchBoxHeader">
            <span class="search-category-title copy-bold text-transform-none">{{
              'bossSearchBox.lastSearched' | cxTranslate
            }}</span>
            <a
              class="boss-searchbox-link copy"
              *ngFor="let result of lastSearchedResults"
              [routerLink]=""
              (mousedown)="preventDefault($event)"
              (click)="launchSearchResult($event, result); close($event); closeMobileSearch()"
            >
              {{ result }}
            </a>
          </div>
        </div>
        <div *ngIf="popularSearchTerms$ | async as popularSearchTerms" class="col-lg-3 col-12">
          <div class="searchBoxHeader">
            <span class="search-category-title copy-bold text-transform-none">{{
              'bossSearchBox.popularSearchTerms' | cxTranslate
            }}</span>
            <a
              class="boss-searchbox-link copy text-capitalize"
              *ngFor="let term of popularSearchTerms"
              [routerLink]="['search', term]"
              (mousedown)="preventDefault($event)"
            >
              {{ term }}
            </a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="bossAutoCompleteResult$ | async as bossAutoCompleteResult">
        <ng-container *ngIf="noResultsFound; else noResults">
          <div class="col-lg-3 col-12">
            <!-- products -->
            <div class="products searchBoxHeader" *ngIf="bossAutoCompleteResult.products">
              <span class="search-category-title copy-bold text-transform-none">{{
                'bossSearchBox.productsHeader' | cxTranslate
              }}</span>
              <a
                class="boss-searchbox-link"
                *ngFor="let product of bossAutoCompleteResult.products"
                [routerLink]="product.url"
                (mousedown)="addElementToLastSearch(product.title); preventDefault($event)"
                (click)="close($event); closeMobileSearch(); cdRef.detectChanges()"
              >
                <cx-media
                  *ngIf="config.displayProductImages"
                  [container]="product.images[0]"
                  [alt]="product.summary"
                ></cx-media>
                <p class="copy" [innerHTML]="buildLabel(product.title, 'copy')"></p>
              </a>
            </div>
            <a
              class="boss-searchbox-link show-all-btn"
              [routerLink]="
                {
                  cxRoute: 'search',
                  params: { query: searchInputValue }
                } | cxUrl
              "
              (keydown.arrowup)="focusPreviousChild($event)"
              (keydown.arrowdown)="focusNextChild($event)"
              (keydown.enter)="close($event); closeMobileSearch()"
              (keydown.escape)="close($event); closeMobileSearch()"
              (blur)="close($event); closeMobileSearch()"
              (mousedown)="preventDefault($event)"
              (click)="close($event); closeMobileSearch(); cdRef.detectChanges()"
            >
              <span class="copy-bold">Alle anzeigen</span>
            </a>
          </div>

          <div class="col-lg-3 col-12">
            <!-- categories -->
            <div class="categories searchBoxHeader" *ngIf="bossAutoCompleteResult.categories">
              <span class="search-category-title copy-bold text-transform-none">{{
                'bossSearchBox.categoriesHeader' | cxTranslate
              }}</span>
              <ng-container *ngIf="bossAutoCompleteResult.categories?.length; else noCategories">
                <a
                  class="boss-searchbox-link"
                  *ngFor="let category of bossAutoCompleteResult.categories"
                  [routerLink]="category.url"
                  (mousedown)="preventDefault($event)"
                  (click)="close($event); closeMobileSearch()"
                >
                  <span class="copy" [innerHTML]="buildLabel(category.name, 'copy') | bossSafe"></span>
                </a>
              </ng-container>
              <ng-template #noCategories>
                <p class="copy">{{ 'bossSearchBox.noMatches' | cxTranslate }}</p>
              </ng-template>
            </div>
          </div>

          <div class="col-lg-3 col-12">
            <!-- suggestions -->
            <div
              class="suggestions searchBoxHeader"
              *ngIf="bossAutoCompleteResult.suggestions?.suggestions as suggestions"
            >
              <span class="search-category-title copy-bold text-transform-none">{{
                'bossSearchBox.suggestionsHeader' | cxTranslate
              }}</span>
              <ng-container *ngIf="suggestions.length; else noSuggestions">
                <a
                  class="boss-searchbox-link"
                  *ngFor="let suggestion of suggestions"
                  [routerLink]="
                    {
                      cxRoute: 'search',
                      params: { query: suggestion.value }
                    } | cxUrl
                  "
                  (keydown.arrowup)="focusPreviousChild($event)"
                  (keydown.arrowdown)="focusNextChild($event)"
                  (keydown.enter)="close($event); closeMobileSearch()"
                  (keydown.escape)="close($event); closeMobileSearch()"
                  (blur)="close($event); closeMobileSearch()"
                  (mousedown)="preventDefault($event)"
                  (click)="close($event); closeMobileSearch()"
                >
                  <p class="copy" [innerHTML]="buildLabel(suggestion.value, 'copy')"></p>
                </a>
              </ng-container>
              <ng-template #noSuggestions>
                <p class="copy">{{ 'bossSearchBox.noMatches' | cxTranslate }}</p>
              </ng-template>
            </div>
          </div>

          <div class="col-lg-3 col-12">
            <!-- stores / markets -->
            <div class="stores searchBoxHeader" *ngIf="bossAutoCompleteResult.market">
              <span class="search-category-title copy-bold text-transform-none">{{
                'bossSearchBox.storeHeader' | cxTranslate
              }}</span>
              <ng-container *ngIf="bossAutoCompleteResult.market?.length; else noMarket">
                <a
                  class="boss-searchbox-link"
                  *ngFor="let store of bossAutoCompleteResult.market"
                  [routerLink]="store.url"
                  (mousedown)="preventDefault($event)"
                  (click)="close($event); closeMobileSearch()"
                >
                  <p class="copy" [innerHTML]="buildLabel(store.displayName, 'copy')"></p>
                </a>
              </ng-container>
              <ng-template #noMarket>
                <p class="copy">{{ 'bossSearchBox.noMatches' | cxTranslate }}</p>
              </ng-template>
            </div>
            <!-- service & career -->
            <div class="service searchBoxHeader" *ngIf="bossAutoCompleteResult.serviceAndCareer">
              <span class="search-category-title copy-bold text-transform-none">{{
                'bossSearchBox.moreContent' | cxTranslate
              }}</span>
              <ng-container *ngIf="bossAutoCompleteResult.serviceAndCareer.length; else noService">
                <a
                  class="boss-searchbox-link"
                  *ngFor="let service of bossAutoCompleteResult.serviceAndCareer"
                  [routerLink]="service.url"
                  (mousedown)="preventDefault($event)"
                  (click)="close($event); closeMobileSearch()"
                >
                  <p class="copy" [innerHTML]="buildLabel(service.name, 'copy')"></p>
                </a>
              </ng-container>
              <ng-template #noService>
                <p class="copy">{{ 'bossSearchBox.noMatches' | cxTranslate }}</p>
              </ng-template>
            </div>
          </div>
        </ng-container>
        <ng-template #noResults>
          <div class="col-lg-6 col-12">
            <h4 class="boss-h4 text-transform-none pl-0"> Entschuldigung </h4>
            <p class="copy"
              >wir konnten leider nichts zu deinem Begriff “<span class="magenta">{{ searchInputValue }}</span
              >” finden.
            </p>
          </div>
          <div class="col-lg-6 col-12">
            <h6 class="boss-h6 text-transform-none">Du brauchst telefonische Beratung? Wende dich gerne an uns</h6>
            <div class="d-flex align-items-start details">
              <i bossIcon class="pr-3" [type]="bossIconConfig.PHONE" aria-hidden="true"></i>
              <div>
                <a
                  href="tel:05731609860"
                  (mousedown)="preventDefault($event)"
                  (click)="close($event); closeMobileSearch()"
                  >05731 - 609 860</a
                >
                <p class="copy">Montags bis Freitags: 8:00 - 20:00 Uhr <br />Samstags: 10:00 - 15:00 Uhr</p>
              </div>
            </div>
            <div class="d-flex align-items-start details">
              <i bossIcon class="pr-3" [type]="bossIconConfig.MAIL_SEND" aria-hidden="true"></i>
              <div>
                <p class="copy"
                  >oder nutze unser
                  <a
                    [routerLink]="['/service/contact/form']"
                    (mousedown)="preventDefault($event)"
                    (click)="close($event); closeMobileSearch()"
                    >Kontaktformular</a
                  ></p
                >
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-container>
