<div
  class="boss-tooltip boss-info-return-amount-tooltip d-inline-block"
  [ngClass]="wrapperClasses"
  [ngStyle]="wrapperStyles"
>
  <ng-content select="[handle]"></ng-content>
  <div class="boss-tooltip__flyout" [ngClass]="containerClasses" [ngStyle]="containerStyles">
    <span class="boss-tooltip__pointer"></span>
    <ng-content></ng-content>
  </div>
</div>
