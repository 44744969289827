<button
  class="boss-hamburger"
  type="button"
  (click)="toggle()"
  [class.is-active]="isExpanded"
  [attr.aria-expanded]="isExpanded"
  [attr.aria-label]="'common.menu' | cxTranslate"
  [class.mobile-menu-expanded]="isMobile && isExpanded"
  aria-controls="header-account-container, header-categories-container, header-locale-container"
>
  <span class="boss-hamburger-box">
    <span class="boss-hamburger-inner"></span>
  </span>
</button>
