<div class="simple-collapse--header {{ headerClasses }}">
  <h2 class="copy-bold mb-0">
    <ng-container *ngIf="header">{{ header }}</ng-container>
    <ng-content select="[header]"></ng-content>
  </h2>

  <button
    (click)="toggleCollapse()"
    [attr.aria-label]="'common.toggleSpecific' | cxTranslate: { name: header }"
    [ngClass]="collapsed ? '' : 'active'"
    class="simple-collapse--icon"
  >
    <i bossIcon [type]="collapsed ? collapsedIcon : expandedIcon"></i>
  </button>
</div>

<div class="simple-collapse--container" [ngStyle]="collapseStyles">
  <div #collapseRef class="simple-collapse--content {{ contentClasses }}">
    <ng-content></ng-content>
  </div>
</div>
