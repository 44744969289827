import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { CmsBannerCarouselComponent, CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { BossCarouselBaseComponent } from '../../shared/components/boss-carousel-base/boss-carousel-base.component';
import { AutoplayOptions, PaginationOptions } from 'swiper/types';

@Component({
  selector: 'boss-banner-automatic-carousel',
  templateUrl: './banner-automatic-carousel.component.html',
  styleUrls: ['./banner-automatic-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossBannerAutomaticCarouselComponent extends BossCarouselBaseComponent implements OnDestroy {
  protected readonly swiperConfigServer: SwiperOptions = {
    navigation: true,
    slidesPerView: 1,
    virtual: false,
  };

  protected readonly swiperConfigBrowser: SwiperOptions = {
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    } as AutoplayOptions,
    slidesPerView: 1,
    navigation: true,
    pagination: {
      clickable: true,
    } as PaginationOptions,
  };

  private onDestroy$ = new Subject<void>();

  constructor(
    private cmsData: CmsComponentData<CmsBannerCarouselComponent>,
    private cms: CmsService,
    private cdRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    super(platformId);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  loadItems(): void {
    this.cmsData.data$
      .pipe(
        filter(Boolean),
        map((data: CmsBannerCarouselComponent) => data?.banners?.trim()?.split(' ') ?? []),
        map((codes) => codes.map((code) => this.cms.getComponentData(code))),
        switchMap((items$) => combineLatest(...items$)),
        takeUntil(this.onDestroy$),
      )
      .subscribe((items) => {
        console.log('banner automatic carousel', items);
        this.items = items;
        this.cdRef.detectChanges();
      });
  }
}
