<div *ngIf="variantsSizes?.length">
  <label class="mb-1 small-copy">{{ BossProductVariantLabel.Size }}</label>
  <boss-dropdown
    [list]="variantsSizes"
    [useRadio]="true"
    [listLabel]="'name'"
    [listValue]="'id'"
    [selectedValue]="selectedSize"
    (selected)="goToProduct($event)"
  ></boss-dropdown>
</div>

<div *ngIf="variantsMaterials?.length">
  <label class="mb-1 small-copy">{{ BossProductVariantLabel.Material }}</label>
  <boss-dropdown
    [list]="variantsMaterials"
    [useRadio]="true"
    [listLabel]="'name'"
    [listValue]="'id'"
    [selectedValue]="selectedMaterial"
    (selected)="goToProduct($event)"
  ></boss-dropdown>
</div>

<div *ngIf="variantsEffectuations?.length">
  <label class="mb-1 small-copy">{{ BossProductVariantLabel.Type }}</label>
  <boss-dropdown
    [list]="variantsEffectuations"
    [useRadio]="true"
    [listLabel]="'name'"
    [listValue]="'id'"
    [selectedValue]="selectedEffectuation"
    (selected)="goToProduct($event)"
  ></boss-dropdown>
</div>
