<a
  *ngFor="let facetValue of facet.values | slice: 0:visibleFacets"
  [queryParams]="getLinkParams(facetValue)"
  [title]="getName(facetValue)"
  class="boss-facet-checkbox"
  [routerLink]=""
>
  <span class="boss-form-control-checkbox" [class.selected]="facetValue?.selected"></span>

  <span class="boss-form-check-label">
    <ng-container *ngIf="facet?.code === bossFilterCodes.COLOR">
      <ng-container *ngIf="facetValue?.colorHexCodeList?.length === 1; else image">
        <span class="boss-facet-checkbox-color" [ngStyle]="{ 'background-color': facetValue?.colorHexCode }"></span>
      </ng-container>

      <ng-template #image>
        <img
          class="boss-facet-checkbox-color"
          loading="lazy"
          [alt]="getName(facetValue)"
          [src]="imageBaseUrl + facetValue?.colorMediaUrl"
        />
      </ng-template>
    </ng-container>

    <span class="boss-facet-checkbox-label">
      {{ getName(facetValue) }}
    </span>

    <span class="boss-facet-checkbox-count">({{ facetValue?.count }})</span>
  </span>
</a>

<button
  class="boss-facet-checkbox-btn boss-btn-as-link"
  *ngIf="visibleFacets < facet?.values?.length"
  (click)="showMore()"
  type="button"
  >{{ 'productFacetNavigation.showMore' | cxTranslate }}</button
>

<button
  class="boss-facet-checkbox-btn boss-btn-as-link"
  *ngIf="visibleFacets > initialCount"
  (click)="showLess()"
  type="button"
  >{{ 'productFacetNavigation.showLess' | cxTranslate }}</button
>
