import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartService, CmsService, OrderEntry, RoutingService, WindowRef } from '@spartacus/core';
import { BREAKPOINT, BreakpointService, KeyboardFocusService, StorefrontComponent } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { UserCentricsService } from 'src/app/spartacus/shared/services/usercentrics.service';
import { BossHamburgerMenuService } from '../hamburger-menu/boss-hamburger-menu.service';
import { BossDynamicYieldService } from '../dynamic-yield/boss-dy.service';
import { BossDYEventType } from '../dynamic-yield/model';

@Component({
  selector: 'boss-storefront',
  templateUrl: './boss-storefront.component.html',
  styleUrls: ['./boss-storefront.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BossStorefrontComponent extends StorefrontComponent implements OnInit, OnDestroy {
  isMobile$: Observable<boolean> = this.breakpointService.isDown(BREAKPOINT.md);

  private subscription = new Subscription();

  readonly isCheckoutPage$ = this.cms
    .getCurrentPage()
    .pipe(
      map(
        (page) =>
          page &&
          (page.template === 'SpaMultiStepCheckoutSummaryPageTemplate' ||
            page.template === 'CheckoutLoginPageTemplate'),
      ),
    );

  constructor(
    private cms: CmsService,
    hamburgerMenuService: BossHamburgerMenuService,
    elementRef: ElementRef<HTMLElement>,
    keyboardFocusService: KeyboardFocusService,
    routing: RoutingService,
    private breakpointService: BreakpointService,
    protected winRef: WindowRef,
    protected uc: UserCentricsService, // Needed to add eventHandler on every Page load.
    protected activatedRoute: ActivatedRoute,
    private dynamicYieldService: BossDynamicYieldService,
    private activeCartService: ActiveCartService,
  ) {
    super(hamburgerMenuService, routing, elementRef, keyboardFocusService);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.isMobile$.pipe(filter((isMobile: boolean) => !isMobile)).subscribe(() => {
        this.collapseMenu();
      }),
    );

    // Dynamic Yield
    this.subscription.add(
      this.activeCartService
        .getEntries()
        .pipe(
          distinctUntilChanged(
            (a: OrderEntry[], b: OrderEntry[]) => JSON.stringify(a.length) === JSON.stringify(b.length),
          ),
        )
        .pipe(
          tap((order: OrderEntry[]) => {
            // Sync cart needs to be done on all pages, at all times, as it's a base for further calculations within DY
            this.dynamicYieldService.triggerEvent({
              name: 'Sync cart',
              properties: {
                dyType: BossDYEventType.SYNC_CART,
                value: order.reduce((acc: number, entry: OrderEntry) => {
                  return acc + entry.totalProductPrice.value;
                }, 0),
                cart: order.map((entry) => ({
                  productId: entry.product.code,
                  quantity: entry.quantity,
                  itemPrice: entry.product.price.value,
                })),
              },
            });
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  collapseMenuIfClickOutside(event: any): void {
    if (this.winRef.isBrowser()) {
      const element = event?.target?.parentNode;
      if (
        // default spa is checking for header, but their code is not working. So we use this
        element?.closest('header') &&
        // for some reason the back menu button is considered to bedirectly part of the header. filter it this way
        !element?.closest('boss-navigation') &&
        // ignore click on hamburger menu
        !element?.className.includes('hamburger') &&
        document.body.classList.contains('mobile-menu-expanded') // check if menu is expanded.
      ) {
        this.collapseMenu();
      }
    }
  }
}
