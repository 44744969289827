import { Component, Input } from '@angular/core';

@Component({
  selector: 'boss-tooltip',
  templateUrl: './boss-tooltip.component.html',
  styleUrls: ['./boss-tooltip.component.scss'],
})
export class BossTooltipComponent {
  @Input() wrapperClasses: any = [];

  @Input() wrapperStyles: { [klass: string]: any } = [];

  @Input() containerClasses: any = [];

  @Input() containerStyles: { [klass: string]: any } = [];
}
