<ng-container *ngIf="activeFacets?.length > 0">
  <div class="boss-active-facets">
    <p class="boss-active-facets-title small-copy-bold">
      {{ 'productList.appliedFilter' | cxTranslate }}
    </p>
    <ng-container *ngFor="let activeFacet of activeFacets">
      <label class="boss-active-facets-chip">
        <span>{{ activeFacet | bossFacetName }}</span>
        <a [queryParams]="getLinkParams(activeFacet)" [routerLink]="" tabindex="0">
          <ng-container *ngIf="isMobile$ | async as isMobile; else desktopIcon">
            <i bossIcon [type]="bossIconConfig.CLOSE" [iconSize]="20" aria-hidden="true"></i>
          </ng-container>
          <ng-template #desktopIcon>
            <i bossIcon [type]="bossIconConfig.CLOSE" [iconSize]="24" aria-hidden="true"></i>
          </ng-template>
        </a>
      </label>
    </ng-container>
    <button
      *ngIf="!isDialog"
      (click)="removeAllFilters()"
      class="boss-active-facets-remove-all boss-link"
      type="button"
      >{{ 'productList.removeAllFilter' | cxTranslate }}</button
    >
  </div>
</ng-container>
