import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { NavigationBarController, NavigationSubLevel } from './navigation.model';
import {
  BOSS_NAVIGATION_BAR_DATA,
  BossTransferStateService,
} from '../../../spartacus/shared/services/boss-transfer-state/index';

@Injectable({
  providedIn: 'root',
})
export class BossNavigationService {
  constructor(
    private http: HttpClient,
    private occEndpoints: OccEndpointsService,
    private bossTransferState: BossTransferStateService,
  ) {}

  getNavigationSubLevels(): Observable<NavigationSubLevel[]> {
    const navigationData: Observable<NavigationSubLevel[]> = this.bossTransferState.get(BOSS_NAVIGATION_BAR_DATA);

    return (
      navigationData ||
      this.http.get(this.occEndpoints.buildUrl('navigationbar')).pipe(
        map((nav: NavigationBarController) => nav.navigationBarComponents),
        tap((data: NavigationSubLevel[]) => {
          this.bossTransferState.set(BOSS_NAVIGATION_BAR_DATA, data);
        }),
        shareReplay(1),
      )
    );
  }
}
