import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { BREAKPOINT, BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable, Subject } from 'rxjs';
import { CmsService } from '@spartacus/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BossPillsComponentData, BossPillsModel } from './boss-pills.model';
import { SwiperOptions } from 'swiper';
import { BossCarouselBaseComponent } from '../boss-carousel-base/boss-carousel-base.component';
import { bossIconConfig } from '../../utils/boss-icon-config';

@Component({
  selector: 'boss-pills',
  templateUrl: './boss-pills.component.html',
  styleUrls: ['./boss-pills.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BossPillsComponent extends BossCarouselBaseComponent implements OnInit, OnDestroy {
  items: BossPillsModel[];

  pillsToDisplay = 0;

  addIcon = bossIconConfig.ADD;

  subtractIcon = bossIconConfig.SUBTRACT;

  isMobile = true;

  isExpanded = false;

  protected readonly swiperConfigBrowser: SwiperOptions = {
    grabCursor: true,
    pagination: true,
    spaceBetween: 16,
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      400: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      576: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
  };

  private onDestroy$ = new Subject<void>();

  private defaultItemsPerLoad = 5;

  constructor(
    private componentData: CmsComponentData<BossPillsComponentData>,
    private cmsService: CmsService,
    private breakpointService: BreakpointService,
    private cdRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    super(platformId);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.handleBreakpoint();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  showMore(): void {
    this.pillsToDisplay = this.items?.length;
    this.isExpanded = true;
  }

  showLess(): void {
    this.pillsToDisplay = this.getDefaultItemsPerLoad();
    this.isExpanded = false;
  }

  protected loadItems(): void {
    this.componentData.data$
      .pipe(
        map((data: BossPillsComponentData) => data?.pills?.trim()?.split(' ') ?? ''),
        map((codes: string[]) => codes.map((code: string) => this.getDataByCode(code))),
        switchMap((pills$: Observable<BossPillsModel>[]) => combineLatest([...pills$])),
        takeUntil(this.onDestroy$),
      )
      .subscribe((pills: BossPillsModel[]) => {
        this.items = pills;
        this.pillsToDisplay = this.getDefaultItemsPerLoad();

        this.cdRef.detectChanges();
      });
  }

  private getDataByCode(code: string): Observable<BossPillsModel> {
    return this.cmsService.getComponentData<BossPillsModel>(code);
  }

  private handleBreakpoint(): void {
    this.breakpointService
      .isDown(BREAKPOINT.sm)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;

        this.cdRef.detectChanges();
      });
  }

  /**
   * Get default number of items to show. It is equal to a default value (5) or items count if we have less than 5 items.
   *
   * @return {*} {number} Default number of items to show
   */
  private getDefaultItemsPerLoad(): number {
    const items = this.items?.length;

    return items < this.defaultItemsPerLoad ? items : this.defaultItemsPerLoad;
  }
}
