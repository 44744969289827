import { Injectable } from '@angular/core';
import { Product } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { JsonLdBuilder } from '@spartacus/storefront';
import { environment } from '../../../../../environments/environment';
import { BossImageConverter } from '../../../shared/utils/image-converter/boss-image.converter';
import { BossProductBase, BossProductBrand, BossProductSchema } from '../models/boss-product-base.model';

/**
 * Builds the basic structured data for the product, see https://schema.org/product.
 * This builder includes data for name, url, description, brand.
 */
@Injectable({
  providedIn: 'root',
})
export class BossJsonLdBaseProductBuilder implements JsonLdBuilder<Product> {
  constructor(private imageConverter: BossImageConverter) {}

  build(product: Product): Observable<any> {
    return of({
      ...this.getProductBase(product),
      ...this.getProductBrand(),
    });
  }

  private getProductBase(product: Product): BossProductBase {
    const result: BossProductBase = {};

    if (product.name) {
      result.name = product.name;
    }

    if (product.url) {
      result.url = environment.mediaBaseUrl + product.url;
    }

    if (product.description) {
      result.description = product.description;
    }

    if (product.images) {
      result.image = this.imageConverter.convertToStringArray(product.images);
    }

    return result;
  }

  protected getProductBrand(): BossProductBrand {
    const schema: BossProductSchema = { '@type': 'Brand', name: 'BOSS' };

    return { brand: schema };
  }
}
