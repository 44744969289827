import { LOCALE_ID, NgModule } from '@angular/core';
import {
  ActiveCartService,
  BadRequestHandler,
  BasePageMetaResolver,
  CartAdapter,
  CategoryPageMetaResolver,
  CMS_FEATURE,
  defaultPageMetaConfig,
  FeaturesConfig,
  I18nConfig,
  MultiCartService,
  OccCmsPageNormalizer,
  PageLinkService,
  PageMetaConfig,
  PageMetaResolver,
  PRODUCT_NORMALIZER,
  ProductAdapter,
  ProductPageMetaResolver,
  provideConfig,
  RouteLoadStrategy,
  RoutingConfig,
  SITE_CONTEXT_FEATURE,
  SiteContextConfig,
  StateConfig,
  StateTransferType,
} from '@spartacus/core';
import { bossIconConfig } from './icons/boss-icon.config';
import { BossBadRequestHandler } from './shared/handlers/boss-bad-request.handler';
import { bossTranslations } from './i18n/translations';
import { bossTranslationChunks } from './i18n/translation-chunks-config';
import { SeoConfig } from '@spartacus/storefront/cms-structure/seo/config';
import {
  HamburgerMenuService,
  LogoutGuard,
  PageSlotService,
  ProductFacetService,
  ProductListComponentService,
  ProductPageEventBuilder,
  ProductVariantGuard,
  PWAModuleConfig,
} from '@spartacus/storefront';
import { bossMediaConfig } from './configuration/boss-media-config';
import { bossGlobalMessageConfig } from './configuration/boss-global-message-config';
import { ROUTER_CONFIGURATION } from '@angular/router';
import { BossOccCmsPageNormalizer } from './shared/normalizer/boss-occ-cmspage-normalizer';
import { BossCartEntryAdapter } from './features/product/product-add-to-cart/boss-cart-entry.adapter';
import { BossOccCartEntryAdapter } from './features/product/product-add-to-cart/boss-occ-cart-entry.adapter';
import { BossPageSlotService } from './shared/services/boss-page-slot.service';
import { BossPageLinkService } from './shared/services/boss-page-link.service';
import { BossCategoryPageMetaResolver } from './shared/resolvers/meta/boss-category-page-meta.resolver';
import { BossBasePageMetaResolver } from './shared/resolvers/meta/boss-base-page-meta.resolver';
import { BossProductPageMetaResolver } from './shared/resolvers/meta/boss-product-page-meta.resolver';
import { BossProductVariantGuard } from './features/product/product-variants/product-variant.guard';
import { BossLogoutGuard } from './shared/guards/boss-user.guard';
import { BossOccProductAdapter } from './features/product/product-add-to-cart/boss-occ-product.adapter';
import { BossProductPageEventBuilder } from './shared/events/boss-product-page-event.builder';
import { BossSearchPageMetaResolver } from './shared/resolvers/meta/search-page-meta.resolver';
import { BossStorePageMetaResolver } from './shared/resolvers/meta/boss-store-page-meta.resolver';
import { BossCartPageMetaResolver } from './shared/resolvers/meta/boss-cart-page-meta.resolver';
import { BossActiveCartService } from './features/product/product-add-to-cart/active-cart.service';
import { BossMultiCartService } from './features/product/product-add-to-cart/multi-cart.service';
import { BossProductNormalizer } from './shared/normalizer/product.normalizer';
import { BossProductHoverImageNormalizer } from './shared/normalizer/boss-product-hover-image-normalizer';
import {
  bossCheckoutReducerProvider,
  bossMultiCartReducerProvider,
  bossPageSizeReducerProvider,
} from './shared/reducers';
import { bossLayoutConfig } from './configuration/boss-layout.config';
import { bossRoutingConfig } from './configuration/boss-routing-config';
import { bossAuthConfig } from './configuration/boss-auth-config';
import { bossSmartEditConfig } from './configuration/boss-smartedit-config';
import { bossPaginationConfig } from './configuration/boss-pagination-config';
import { bossOccConfig } from './configuration/boss-occ-config';
import { BossProductFacetService } from './shared/services/product-facet.service';
import { BossHamburgerMenuService } from './features/hamburger-menu/boss-hamburger-menu.service';
import { BossOccCartAdapter } from './shared/occ/category/boss-occ-cart-adapter';
import { BossProductListComponentService } from './features/product/product-list/services/product-list-component.service';

@NgModule({
  providers: [
    provideConfig({
      state: {
        ssrTransfer: {
          keys: {
            [CMS_FEATURE]: StateTransferType.TRANSFER_STATE,
            [SITE_CONTEXT_FEATURE]: StateTransferType.TRANSFER_STATE,
          },
        },
      },
    } as StateConfig),
    provideConfig({
      routing: {
        loadStrategy: RouteLoadStrategy.ONCE,
      },
    } as RoutingConfig),
    provideConfig({
      pwa: {
        enabled: false,
      },
    } as PWAModuleConfig),
    provideConfig(bossOccConfig),
    provideConfig(bossIconConfig),
    { provide: BadRequestHandler, useClass: BossBadRequestHandler },
    provideConfig({
      seo: {
        structuredData: {
          disableInDevMode: false,
        },
      },
    } as SeoConfig),
    provideConfig({
      pageMeta: {
        resolvers: [
          ...defaultPageMetaConfig.pageMeta.resolvers,
          {
            property: 'canonicalUrl',
            method: 'resolveCanonicalUrl',
            disabledInCsr: false,
          },
        ],
        canonicalUrl: {
          forceTrailingSlash: false,
        },
      },
    } as PageMetaConfig),
    provideConfig({
      features: {
        level: '3.1',
        shrunkPricePromotion: false,
      },
    } as FeaturesConfig),
    provideConfig({
      i18n: {
        resources: bossTranslations,
        chunks: bossTranslationChunks,
        fallbackLang: 'de',
      },
    } as I18nConfig),
    provideConfig({
      context: {
        currency: ['EUR'],
        language: ['de'],
        baseSite: ['boss'],
      },
    } as SiteContextConfig),
    provideConfig(bossLayoutConfig),
    provideConfig(bossRoutingConfig),
    provideConfig(bossAuthConfig),
    provideConfig(bossSmartEditConfig),
    provideConfig(bossMediaConfig),
    provideConfig(bossGlobalMessageConfig),
    provideConfig(bossPaginationConfig),
    {
      provide: ROUTER_CONFIGURATION,
      useValue: {
        anchorScrolling: 'enabled',
        relativeLinkResolution: 'corrected',
        initialNavigation: 'enabled',
        onSameUrlNavigation: 'reload',
      },
    },
    { provide: OccCmsPageNormalizer, useClass: BossOccCmsPageNormalizer },
    { provide: BossCartEntryAdapter, useClass: BossOccCartEntryAdapter },
    { provide: CartAdapter, useClass: BossOccCartAdapter },
    { provide: PageSlotService, useClass: BossPageSlotService },
    { provide: PageLinkService, useClass: BossPageLinkService },
    { provide: ProductListComponentService, useClass: BossProductListComponentService },
    {
      provide: CategoryPageMetaResolver,
      useClass: BossCategoryPageMetaResolver,
    },
    { provide: BasePageMetaResolver, useClass: BossBasePageMetaResolver },
    { provide: ProductPageMetaResolver, useClass: BossProductPageMetaResolver },
    { provide: ProductVariantGuard, useClass: BossProductVariantGuard },
    { provide: LogoutGuard, useClass: BossLogoutGuard },
    { provide: ProductAdapter, useClass: BossOccProductAdapter },
    {
      provide: ProductPageEventBuilder,
      useExisting: BossProductPageEventBuilder,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: PageMetaResolver,
      useExisting: BossSearchPageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: BossStorePageMetaResolver,
      multi: true,
    },
    {
      provide: PageMetaResolver,
      useExisting: BossCartPageMetaResolver,
      multi: true,
    },
    {
      provide: ActiveCartService,
      useExisting: BossActiveCartService,
    },
    {
      provide: MultiCartService,
      useExisting: BossMultiCartService,
    },
    {
      provide: ProductFacetService,
      useExisting: BossProductFacetService,
    },
    {
      provide: HamburgerMenuService,
      useExisting: BossHamburgerMenuService,
    },
    {
      provide: PRODUCT_NORMALIZER,
      useClass: BossProductNormalizer,
      multi: true,
    },
    {
      provide: PRODUCT_NORMALIZER,
      useClass: BossProductHoverImageNormalizer,
      multi: true,
    },
    bossCheckoutReducerProvider,
    bossMultiCartReducerProvider,
    bossPageSizeReducerProvider,
  ],
})
export class SpartacusConfigurationModule {}
